<div class="underwrite-approval-page-container">
  <h2
    id="offerTextSection"
    [innerHtml]="subHeader | safeHtml"
    [ngClass]="{
      'counteroffer-subheader': isCounterOffer,
      'submitted-consent': isConsentSubmitted
    }"
  ></h2>

  <div
    id="counterOfferApprovedAmount"
    *ngIf="isCounterOffer"
    class="ecl-font-hero counteroffer-approved-amount ecl-font-color-text ecl-rise-color-text"
  >
    {{ formattedApprovalAmount }}
  </div>
  <div
    *ngIf="isCounterOffer"
    id="counterOfferSubTextId"
    [ngClass]="{ 'submitted-consent': isConsentSubmitted }"
    [innerHtml]="counterOfferSubText"
  ></div>

  <section *ngIf="content?.approvalSteps" class="approval-steps">
    <span class="ecl-font-body-medium approval-steps-header">
      {{ content.approvalSteps.header }}</span
    >
    <p [innerHTML]="content.approvalSteps.stepsText | safe: 'html'"></p>
  </section>

  <ng-container *ngIf="isAbTestControl; else nonAbTestControl">
    <form *ngIf="!emailVerified" [formGroup]="form" (submit)="onSubmit()">
      <div class="married-content" *ngIf="isWisconsin">
        <p id="maritalHeader" class="ecl-font-body-bold">
          {{ content.maritalStatus.header | uppercase }}
        </p>
        <p id="maritalContent">
          {{ content.maritalStatus.content }}
        </p>
        <div class="email-input">
          <ecl-select
            [formControlConfig]="maritalStatusConfig"
            formControlName="maritalStatus"
            (change)="showAdditionalQuestions($event)"
          >
            {{ content.maritalStatus.maritalStatusSelect.label }}
          </ecl-select>
        </div>
        <app-married *ngIf="hideAdditionalQuestions" [formGroup]="form">
        </app-married>
      </div>
      <div [ngClass]="{ 'rise-class': brand === 'Rise' }">
        <div
          [ngClass]="
            isCounterOffer ? 'ecl-font-black-300' : 'ecl-font-color-text'
          "
          id="emailHeader"
        >
          {{ content.content.header }}
        </div>
        <p class="message-txt">
          {{ messageText }}
        </p>
      </div>
      <div class="email-input">
        <ecl-text-input
          id="confirmationCodeInput"
          [formControlConfig]="emailCodeConfig"
          formControlName="confirmationCode"
          googleAnalyticsFocusOut="approvalConfirmationCode"
        >
          {{ content.labels.confirmationCode }}
        </ecl-text-input>
      </div>
      <div class="ecl-button-hgroup ecl-wrap-reverse continue-button-container">
        <button
          focusOnError
          type="submit"
          id="approvalContinueButton"
          googleAnalyticsClick="Acceptance-Continue"
          class="ecl-button-primary"
        >
          {{ content.buttons.continue | uppercase }}
        </button>
        <button
          (click)="resendConfirmationCode()"
          type="button"
          id="approvalResendButton"
          class="ecl-button-secondary"
          googleAnalyticsClick="ResendConfirmationCode"
        >
          {{ content.buttons.resendCode | uppercase }}
        </button>
      </div>
    </form>
    <ng-container *ngIf="!emailVerified; else skipContinueBtn"> </ng-container>
  </ng-container>

  <!-- disclosures consent -->
  <ng-template #nonAbTestControl>
    <ng-container *ngIf="isConsentSubmitted">
      <ng-container *ngTemplateOutlet="messageContainer"></ng-container>
    </ng-container>
    <ng-container *ngIf="!isConsentSubmitted; else skipContinueBtn">
      <div [formGroup]="signInSubmitForm">
        <div class="sign-submit-checkboxes-container">
          <app-consents
            #consentsComponent
            [formGroup]="signInSubmitForm"
            [consentSectionItems]="content?.[type].consentSection"
            [hideDividingLine]="true"
          ></app-consents>
        </div>
        <div
          *ngIf="content?.[type].labels.signatureInputEnabled"
          class="sign-submit-input-container"
        >
          <app-typed-name
            [agreement]="agreementConfig"
            [formGroup]="signInSubmitForm"
          ></app-typed-name>
        </div>
        <div
          *ngIf="content?.[type].buttons.buttonPreText"
          class="sign-submit-button-subtext"
        >
          <span> <i class="ecl-info-icon"></i> </span>

          <span
            [innerHTML]="
              content?.[type].buttons.buttonPreText | safe: 'html'
            "
          ></span>
        </div>
        <ng-container *ngTemplateOutlet="messageContainer"></ng-container>
        <div class="sign-submit-button-container">
          <button
            focusOnError
            id="signSubmitContinueButton"
            type="submit"
            class="ecl-button-primary"
            (click)="onContinue()"
            googleAnalyticsClick="SignSubmitContinue"
          >
            {{ content?.[type].buttons.continue | uppercase }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-template>
  <!-- disclosures consent -->
</div>

<ng-template #messageContainer>
  <div [innerHTML]="content?.[type].message | safe: 'html'"></div>
</ng-template>

<!-- skip page -->
<ng-template #skipContinueBtn>
  <div class="continue-button-container">
    <button
      (click)="skipEmailVerification()"
      id="skipConfirmationContinueButton"
      class="ecl-button-primary"
      googleAnalyticsClick="SkipConfirmationContinueButton"
    >
      {{ content.buttons.skipEmailVerification | uppercase }}
    </button>
  </div>
</ng-template>
<!-- skip page -->

<app-debug-hud *ngIf="isAbTestControl">
  <button (click)="populateConfirmationCode()">
    Populate Confirmation Code
  </button>
  <button (click)="skipEmailVerification()">
    Skip Email Verification
  </button>
</app-debug-hud>

<app-debug-hud *ngIf="!isAbTestControl">
  <button (click)="debugHudSign()">
    sign disclosures
  </button>
</app-debug-hud>
