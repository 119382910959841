import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { ApplicationFlow } from '@application/application';
import {
  CustomerApiService,
  CustomerResponse
} from '@core/customer/customer-api.service';
import { ApplicationDataService } from '@application/application.service';
import { ApplicationContinuePathGuard } from '@application/continue-path/continue-path.guard';

@Injectable({ providedIn: 'root' })
export class CustomerResolver {
  constructor(
    private customerApi: CustomerApiService,
    private applicationDataService: ApplicationDataService,
    private appGuard: ApplicationContinuePathGuard
  ) {}

  public async resolve(): Promise<CustomerResponse> {
    const application = this.applicationDataService.getApplication();

    if (!this.appGuard.isAuthenticated || Object.keys(application).length > 0) {
      return null;
    } else {
      return await lastValueFrom(this.customerApi.getCustomerInfo());
    }
  }
}
