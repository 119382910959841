import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbTestingData,
  ApplicationData,
  ApplicationFlow,
  ApplicationForm
} from '@application/application';
import {
  AppendDeviceRequest,
  ApplicationApi,
  CreateApplicationRequest
} from '@application/application.api';
import { IovationService } from '@core/iovation/iovation.service';
import { Environment } from '@environment/environment';
import { lastValueFrom, ReplaySubject, Subscription } from 'rxjs';

import { VerifyInfoFormGroup } from '../verify-info.form';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import {
  SessionStorageKeys,
  SessionStorageObjects,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { CurrencyPipe } from '@angular/common';
import * as GettingStartedFormConfig from '../../getting-started/getting-started.form.config';
import { StateEligibilityService } from '@application/getting-started/state-eligibility/state-eligibility.service';
import { CmsService } from '@core/cms';
import {
  CmsVerifyCustomerInfo,
  PolicySection,
  StateLicenses
} from '../verify-info';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import { CustomerResponse } from '@core/customer/customer-api.service';
import { StateMessageService } from '@core/state-message/state-message-service';
import { StateNotServicedModalComponent } from '@application/getting-started/state-not-serviced-modal/state-not-serviced-modal.component';
import { StateMessageModalComponent } from '@application/getting-started/state-message-modal/state-message-modal.component';
import { DefaultModalComponent } from '@core/default-modal/default-modal.component';
import { checkPoBoxValidator } from '@core/customer/no-po-box.validator';
import moment from 'moment';

@Component({
  selector: 'app-verify-customer-info',
  templateUrl: './verify-customer-info.component.html',
  styleUrls: ['../verify-info.component.scss'],
  providers: [VerifyInfoFormGroup],
  encapsulation: ViewEncapsulation.None
})
export class VerifyCustomerInfoComponent implements OnInit, OnDestroy {
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;
  public formConfig = GettingStartedFormConfig;
  public pageContent: SafeHtml;
  public pageSubTitle: string;
  public maskedSSN: string;
  public maskedEmail: string;
  public applicant: any;
  public application: ApplicationData;
  public routeSubscription: Subscription;
  public content: CmsVerifyCustomerInfo;
  public formChangesSubscription: Subscription;
  public isLoanServiced: boolean;
  public appendDeviceRequest: AppendDeviceRequest;
  public stateLicenses: StateLicenses;
  public licenseSubtext: string;
  public policySection: PolicySection[];
  public lenderCode: string;
  public currentState: string;
  public applicationFlow: ApplicationFlow;
  public customer: CustomerResponse;
  private nextPage: string;
  public abTestingNode?: AbTestingData[];

  constructor(
    public form: VerifyInfoFormGroup,
    public modalService: NgbModal,
    private route: ActivatedRoute,
    private environment: Environment,
    public stateMessageService: StateMessageService,
    private iovationService: IovationService,
    private applicationApi: ApplicationApi,
    private router: Router,
    private loadingService: LoadingModalService,
    private pageHeaderService: CmsPageContentService,
    private applicationFlowService: ApplicationFlowService,
    public googleAnalytics: GoogleAnalytics,
    private sessionStorageService: SessionStorageService,
    public currencyPipe: CurrencyPipe,
    public stateEligibilityService: StateEligibilityService,
    private cmsService: CmsService,
    private neuroIdService: NeuroIdService,
    @Inject('window') private window: Window
  ) {
    this.customer = this.route.snapshot.data?.customer;
  }

  public async ngOnInit(): Promise<void> {
    this.content = this.route.snapshot.data.cmsContent.prequalificationVerifyInfo;
    this.pageHeaderService.updatePageTitle(this.content.header);
    this.setLenderCode();
    this.applicationFlow = ApplicationFlow.Former;
    await lastValueFrom(
      this.stateEligibilityService.getStateResource(this.customer.stateCode)
    ).then(res => {
      this.isLoanServiced = res.isLoanServiced;
      if (this.isLoanServiced) {
        const message = this.stateMessageService.getStateMessage(
          this.customer.stateCode,
          this.route.snapshot.data.cmsContent.stateMessages
        );
        if (message) {
          const stateModalRef = this.modalService.open(
            StateMessageModalComponent,
            {
              windowClass: 'state-message-modal',
              centered: true
            }
          );
          this.content.stateNotServiced.content = message.message;
          stateModalRef.componentInstance.htmlContent = this.content.stateNotServiced;
        }
      } else {
        this.stateNotServiced();
      }
    });

    this.abTestingNode = [
      {
        testName: 'AbTestingRedirectToBlueprint',
        segmentName: 'RedirectToBlueprintPlatform'
      } as AbTestingData
    ];

    if (!this.applicationFlowService.getApplicationFlows()) {
      await this.applicationFlowService.setApplicationFlows();
    }

    this.nextPage = this.applicationFlowService.getContinuePath(
      null,
      this.applicationFlow
    );

    if (this.content.policySection != null) {
      this.getStateLenderBasedPolicy(this.content.policySection);
    }

    this.maskedEmail = this.emailShortFormat(this.customer.emailAddress);
    this.getStateLicenses();
    this.pageSubTitle = this.content.subHeader;
  }

  getStateLenderBasedPolicy(policies: PolicySection[]) {
    this.policySection = policies.filter(
      x =>
        x?.lender === this.lenderCode || x?.states?.includes(this.currentState)
    );
  }

  public showLast4(ssn: string): string {
    const last4ssn = ssn.slice(ssn.length - 4);
    return `***-**-${last4ssn}`;
  }

  public async getStateLicenses() {
    await lastValueFrom(this.cmsService.getEntries('state_license')).then(
      data => {
        let licenses = Array.isArray(data) ? data[0] : data;
        if (licenses) {
          const lender = licenses.group?.filter(
            group => group.lender == this.lenderCode
          )[0];
          this.licenseSubtext = lender?.subtext;
          this.stateLicenses = lender?.stateLicenses?.filter(
            stateCode => stateCode.state === this.customer.stateCode
          )[0];
          if (this.stateLicenses) {
            this.stateLicenses.file = Array.isArray(this.stateLicenses.file)
              ? this.stateLicenses.file
              : [this.stateLicenses.file];
          }
        }
      }
    );
  }

  public emailShortFormat(email: string): string {
    if (email.length > 25) {
      const emailId = email.split('@');
      const firstThree = emailId[0].substring(0, 3);
      const lastThree = emailId[0].substring(emailId[0].length - 3);

      return `${firstThree}****${lastThree}@${emailId[1]}`;
    }

    return email;
  }

  public onEdit(): void {
    const bauLoginUrl = `${this.environment.bau.secureUrl}/apps/settings`;
    this.window.location.href = bauLoginUrl;
  }

  public async onSubmit(): Promise<void> {
    this.loadingService.open();
    const invalidAddress =
      checkPoBoxValidator(this.customer.addressLine1) ||
      checkPoBoxValidator(this.customer.addressLine2);

    const invaildMobileNumber = this.customer?.phoneNumber.length !== 10;
    if (invalidAddress || invaildMobileNumber) {
      this.loadingService.close();
      const invalidValuesModal = this.modalService.open(DefaultModalComponent, {
        windowClass: 'invalid-value-message-modal',
        ariaLabelledBy: 'Invalid Customer Data',
        centered: true
      });
      if (invalidAddress) {
        invalidValuesModal.componentInstance.htmlContent = this.content.invalidAddress;
      } else {
        invalidValuesModal.componentInstance.htmlContent = this.content.invalidMobile;
      }
      return;
    }

    this.form.showValidationErrors();

    if (!this.form.valid) {
      this.loadingService.close();
      return;
    }

    const blackBoxResponse = await lastValueFrom(
      this.iovationService.getIovationSignature()
    );
    this.appendDeviceRequest = {
      iovationRequestType: this.environment.iovation.requestType,
      iovationSignature: blackBoxResponse.blackbox,
      neuroID: this.neuroIdService.neuroIdSessionId
    };
    let gcid = this.sessionStorageService.getItem(
      SessionStorageKeys.applicationGCID
    );

    if (gcid !== 'undefined') {
      this.appendDeviceRequest.GCID = gcid;
    }

    let formData: ApplicationForm = {
      continuePath: this.nextPage,
      applicant: {
        identity: {
          socialSecurityNumber: this.customer.ssn,
          firstName: this.customer.firstName,
          lastName: this.customer.lastName,
          dateOfBirth: moment(this.customer.dateOfBirth).format('YYYY-MM-DD')
        },
        emails: [
          {
            key: '1',
            address: this.customer.emailAddress,
            type: 'Personal'
          }
        ],
        phones: [
          {
            key: '1',
            number: this.customer.phoneNumber,
            type: 'Mobile'
          }
        ],
        residences: [
          {
            key: '1',
            type: 'Current',
            address: {
              zipCode: this.customer.zipCode,
              stateCode: this.customer.stateCode,
              line1: this.customer.addressLine1,
              city: this.customer.city
            }
          }
        ]
      },
      disclosures: [
        {
          key: 'PrivacyPolicy',
          consentGiven: true
        },

        {
          key: 'MailOptIn',
          consentGiven: true
        },
        {
          key: 'TextOptIn',
          consentGiven: Boolean(this.form.value.consentToCommunication)
        },
        {
          key: 'PhoneOptIn',
          consentGiven: Boolean(this.form.value.consentToCommunication)
        },
        {
          key: 'ThirdPartyOptIn',
          consentGiven: true
        },
        {
          key: 'ArbitrationOptIn',
          consentGiven: true
        },
        {
          key: 'LegalDiscourseOptIn',
          consentGiven: true
        }
      ]
    };
    if (this.customer.addressLine2) {
      formData.applicant.residences[0].address.line2 = this.customer.addressLine2;
    }
    if (this.customer.nameSuffix) {
      formData.applicant.identity.suffix = this.customer.nameSuffix;
    }
    Array.prototype.push.apply(
      formData.disclosures,
      this.consentsComponent?.disclosures
    );

    var leadData = this.sessionStorageService.getObject(
      SessionStorageObjects.partnerOfferInfo
    );

    if (!leadData) {
      leadData = this.sessionStorageService.getObject(
        SessionStorageObjects.partnerFormerOfferInfo
      );
    }
    let createPayload: CreateApplicationRequest = {
      brand: this.environment.brand,
      applicationFlow: this.applicationFlow,
      form: formData,
      abTesting: this.abTestingNode
    };
    if (leadData) {
      createPayload.partner = {
        leadId: leadData?.lid,
        offerId: leadData?.oid
      };
    }
    await lastValueFrom(
      this.applicationApi.createCustomer(createPayload, this.applicationFlow)
    ).then(
      async httpResponse => {
        this.sessionStorageService.setItem(
          SessionStorageKeys.authorizationToken,
          httpResponse.token
        );

        try {
          await lastValueFrom(
            this.applicationApi.appendDevice(this.appendDeviceRequest)
          );
          this.router.navigate([this.nextPage]);
        } catch {
          this.router.navigate(['/error']);
        }
      },
      httpError => {
        if (httpError.error?.message?.includes('is not Serviced')) {
          this.loadingService.close();
        }
        this.loadingService.close();
      }
    );
  }

  public debugHudFillSubmit(): void {
    this.consentsComponent.debugHudSubmitAllConsents();
    this.onSubmit();
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  public stateNotServiced(): void {
    const stateNotModalRef = this.modalService.open(
      StateNotServicedModalComponent,
      {
        windowClass: 'state-not-serviced-modal',
        ariaLabelledBy: 'State Not Serviced'
      }
    );
    stateNotModalRef.componentInstance.htmlContent = this.content.stateNotServiced;
  }

  public setLenderCode(): void {
    switch (this.customer.productId) {
      case '2700':
        this.lenderCode = 'CCB';
        break;
      case '2600':
        this.lenderCode = 'FNWS';
        break;
      default:
        this.lenderCode = 'ELVT';
        break;
    }
  }
}
