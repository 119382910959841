import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import {
  ApplicationState,
  CustomerApiService,
  CustomerStatus,
  RedirectState
} from '@core/customer/customer-api.service';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { Environment } from '@environment/environment';
import { lastValueFrom } from 'rxjs';

export interface CmsCheckingOptions {
  messages: CmsWaitingMessage[];
}

export interface CmsWaitingMessage {
  message: string;
  secondsDelay: number;
}
@Component({
  selector: 'app-check-status',
  templateUrl: './check-status.component.html',
  styleUrl: './check-status.component.scss'
})
export class CheckStatusComponent {
  private cmsContent: any;
  private currentMessage = 0;
  public displayMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cmsPageContentService: CmsPageContentService,
    public googleAnalytics: GoogleAnalytics,
    private customerApi: CustomerApiService,
    private router: Router,
    private environment: Environment,
    private sessionStorageService: SessionStorageService,
    @Inject('window') private window: Window
  ) {
    this.cmsContent = this.activatedRoute.snapshot.data.cmsContent
      .checkStatusPage as CmsCheckingOptions;
    this.cmsPageContentService.updatePageTitle('');
    this.messageCycle();
    this.getCustomerStatus();
  }

  private messageCycle(): void {
    this.showMessage(this.currentMessage);
    if (this.currentMessage < this.cmsContent.messages.length - 1) {
      setTimeout(
        this.messageCycle.bind(this),
        this.cmsContent.messages[this.currentMessage].secondsDelay * 1000
      );
      this.currentMessage++;
    }
  }

  private async getCustomerStatus(): Promise<void> {
    await lastValueFrom(this.customerApi.getCustomerStatus())
      .then((customerStatus: CustomerStatus) => {
        if (!customerStatus?.token) {
          this.router.navigate(['error']);
        } else {
          this.sessionStorageService.setItem(
            SessionStorageKeys.authorizationToken,
            customerStatus.token
          );
          if (
            Object.keys(RedirectState).indexOf(
              ApplicationState[customerStatus.applicationState]
            ) !== -1
          ) {
            this.router.navigate(['pending']);
          } else {
            this.router.navigate(['error']);
          }
        }
      })
      .catch((error: any) => {
        const bauLoginUrl = `${this.environment.bau.secureUrl}/${this.environment.navigation.login}`;
        this.window.location.href = bauLoginUrl;
      });
  }

  private showMessage(num: number): void {
    this.displayMessage = this.cmsContent.messages[num].message;
  }
}
