<div class="page-container">
  <div class="section">
    <div
      class="section-header ecl-font-section-header-color-xsmall display-heading-xs"
      id="mainIncomeLabel"
    >
      {{ content.labels.mainIncome }}
    </div>

    <div class="section-row">
      <label class="income-verify-label" id="mainSourceLabel"
        >{{ content.labels.mainSource }}:</label
      >
      <div id="incomeVerificationPrimarySource">{{ income.primarySource }}</div>
    </div>

    <div class="section-row">
      <label class="income-verify-label" id="frequencyLabel"
        >{{ content.labels.frequency }}:</label
      >
      <div id="incomeVerificationPrimaryPaymentFrequency">
        {{ income.primaryPaymentFrequency }}
      </div>
    </div>

    <div *ngIf="showPayDates" class="section-row">
      <label class="income-verify-label" id="nextPayDateLabel"
        >{{ content.labels.nextPayDate }}:</label
      >
      <div id="incomeVerificationPrimaryNextPayDate">
        {{ income.nextPayDate | date: 'MM/dd/yyyy' }}
      </div>
    </div>

    <div class="section-row">
      <label class="income-verify-label" id="takeHomeAmountLabel"
        >{{ content.labels.takeHomeAmount }}:</label
      >
      <div id="incomeVerificationPrimaryNetAmount">
        {{ income.primaryNetAmount | currency: 'USD':'symbol':'1.0-0' }}
      </div>
    </div>
  </div>

  <hr class="income-verify-hr" />

  <div class="section">
    <div
      class="section-header ecl-font-section-header-color-xsmall display-heading-xs"
      id="secondaryIncomeLabel"
    >
      {{ content.labels.secondaryIncome }}
    </div>

    <div class="section-row">
      <label class="income-verify-label" id="secondarySourceLabel"
        >{{ content.labels.secondarySource }}:</label
      >
      <div id="incomeVerificationSecondarySource">
        {{ income.secondarySource || 'None' }}
      </div>
    </div>

    <div class="section-row" *ngIf="income.secondarySource">
      <label class="income-verify-label" id="monthlyNetIncomeLabel"
        >{{ content.labels.monthlyNetIncome }}:</label
      >
      <div id="incomeVerificationSecondaryNetAmount">
        {{ income.secondaryNetAmount | currency: 'USD':'symbol':'1.0-0' }}
      </div>
    </div>
  </div>
  <div *ngIf="isABTestControl">
    <hr class="income-verify-hr" />

    <div class="section">
      <div
        class="section-header ecl-font-section-header-color-xsmall display-heading-xs"
        id="additionalInformationLabel"
      >
        {{ content.labels.additionalInformation }}
      </div>

      <div class="section-row">
        <label class="income-verify-label" id="monthlyRentOrMortgageLabel"
          >{{ content.labels.monthlyRentOrMortgage }}:</label
        >
        <div id="incomeVerificationMonthlyRentMortgage">
          {{ income.monthlyRentMortgage | currency: 'USD':'symbol':'1.0-0' }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isABTestControl">
    <hr class="income-verify-hr" />

    <div *ngIf="showPayDates" class="section">
      <div
        class="section-header ecl-font-section-header-color-xsmall display-heading-xs"
        id="confirmLabel"
      >
        {{ content.labels.confirm }}
      </div>

      <div *ngFor="let incomeDate of incomeDates">
        <div class="income-date">
          {{ incomeDate }}
        </div>
      </div>
    </div>
  </div>
  <hr class="income-verify-hr last-divider" />

  <div class="verification-message" id="Label">
    {{ content.disclosures }}
  </div>

  <app-consents
    #consentsComponent
    [formGroup]="form"
    [consentSectionItems]="consentsSection"
  ></app-consents>
  <div
    *ngIf="isABTestControl"
    class="continue-button-container ecl-button-hgroup ecl-wrap-reverse"
  >
    <ng-container [ngTemplateOutlet]="continueBtn"> </ng-container>
    <ng-container [ngTemplateOutlet]="editBtn"> </ng-container>
  </div>

  <div
    *ngIf="!isABTestControl"
    class="continue-button-container ecl-button-hgroup ecl-wrap-reverse"
  >
    <ng-container [ngTemplateOutlet]="editBtn"> </ng-container>
    <ng-container [ngTemplateOutlet]="continueBtn"> </ng-container>
  </div>
</div>
<app-debug-hud>
  <button *ngIf="formContainsConsents" (click)="debugHudSubmitConsents()">
    Submit Consents
  </button>
</app-debug-hud>

<ng-template #continueBtn>
  <button
    id="incomeVerificationSubmitBtn"
    class="ecl-button-primary"
    googleAnalyticsClick="VerifyIncome-Continue"
    (click)="submit()"
    [disabled]="applicationSubmitted"
  >
    {{ isABTestControl ? content.buttons.continue : content.buttons.submit }}
  </button>
</ng-template>
<ng-template #editBtn>
  <button
    id="incomeVerificationEditButton"
    type="button"
    class="ecl-button-secondary"
    googleAnalyticsClick="VerifyIncome-Edit"
    (click)="edit()"
  >
    {{ content.buttons.edit }}
  </button>
</ng-template>
