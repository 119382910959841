import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationContinuePathGuard } from '@application/continue-path/continue-path.guard';
import { CookieEnums, CookieService } from '@core/cookie/cookie-service';
import { LocationProxy } from '@core/location-proxy/location-proxy.service';
import { Environment } from '@environment/environment';
import { Observable, catchError } from 'rxjs';

export interface CustomerResponse {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  customerId?: string;
  customerStatus?: string;
  dateOfBirth?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  ssn?: string;
  stateCode?: string;
  nameSuffix?: string;
  zipCode?: string;
  routingNumber?: string;
  bankAcctNumber?: string;
  productId?: string;
}

export enum ApplicationState {
  Closed = 0,
  InProgress = 'InProgress',
  Declined = 'Declined',
  Approved = 'Approved',
  Accepted = 'Accepted',
  Booked = 'Booked',
  Cancelled = 'Cancelled',
  InformationNeeded = 'InformationNeeded',
  InReview = 'InReview',
  PendingDecision = 'PendingDecision'
}

export enum RedirectState {
  Declined = 'Declined',
  Approved = 'Approved',
  InformationNeeded = 'InformationNeeded',
  InReview = 'InReview',
  PendingDecision = 'PendingDecision'
}

export class CustomerStatus {
  public applicationState: ApplicationState;
  public token: string;
}

@Injectable({ providedIn: 'root' })
export class CustomerApiService {
  constructor(
    private environment: Environment,
    private httpClient: HttpClient,
    private locationProxy: LocationProxy,
    private cookieService: CookieService,
    private appGuard: ApplicationContinuePathGuard
  ) {}

  /* istanbul ignore next */
  public getCustomerInfo(): Observable<CustomerResponse> {
    return this.httpClient
      .get<CustomerResponse>(
        `${this.environment.brandApi.url}/api/v1/customer?brand=${this.environment.brand}`,
        {
          withCredentials: true
        }
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          const bauLoginUrl = `${this.environment.bau.secureUrl}/${this.environment.navigation.login}`;
          if (httpError.status === 401 && this.appGuard.isAuthenticated) {
            this.cookieService.remove(CookieEnums.jwtToken);
            this.cookieService.remove(CookieEnums.riseToken);
            this.locationProxy.assign(bauLoginUrl);
          }
          throw error;
        })
      );
  }

  public getCustomerStatus(): Observable<CustomerStatus> {
    return this.httpClient.get<CustomerStatus>(
      `${this.environment.brandApi.url}/api/v1/customer/application-status`,
      {
        withCredentials: true
      }
    );
  }
}
