import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import {
  ConsentModal,
  ConsentModalContent
} from '@application/consents/consents.content';
import { BaseModalConfig } from '@elevate/base-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbar } from 'ngx-scrollbar';
import { lastValueFrom, Subscription } from 'rxjs';
import { IESiganture } from '../e-signature/interfaces/e-siganture.interface';
import {
  ConsentModalAgreement,
  SignatureHelper,
  SignatureModel,
  SignatureType,
  DisclosureModalEmitDataOnSubmit
} from '../e-signature/signature.content';
import { DisclosureModalFormGroup } from './disclosure-modal.form';
import {
  GoogleAnalytics,
  ModalClickAction
} from '@core/google-analytics/googleanalytics.service';
import { ApplicationDataService } from '@application/application.service';
import { ApplicationData } from '@application/application';
import moment from 'moment';
import { DocumentHtmlContentComponent } from '../document-html-content/document-html-content.component';
import { EnrollmentCodeStatesResponse } from '@core/configuration/configuration.service';
import { Environment } from '@environment/environment';
@Component({
  selector: 'app-disclosure-modal',
  templateUrl: './disclosure-modal.component.html',
  styleUrls: ['./disclosure-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DisclosureModalComponent
  implements AfterViewInit, OnDestroy, OnInit {
  @Input() public consentModal: ConsentModal;
  @Input() public inputSignatures: SignatureModel[];
  @Output() public submitClick: EventEmitter<
    DisclosureModalEmitDataOnSubmit
  > = new EventEmitter<DisclosureModalEmitDataOnSubmit>();
  @Output() public cancelClick: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('contentContainer') private contentContainer: ElementRef;
  @ViewChildren('signature') signatureChildren: QueryList<IESiganture>;
  @ViewChild('scrollbarRef') scrollbarRef: NgScrollbar;
  @ViewChild(DocumentHtmlContentComponent)
  documentComponent: DocumentHtmlContentComponent;
  public scrollDownFirstLabel: string;
  public contentDocumentText: string;
  public scrollDownSecondLabel: string;
  public isScrollDownMandatory = true;
  public isScrollTillEndOfModal = false;
  public isSeparateLineVisible: boolean;
  public date: string;
  public configFeature: EnrollmentCodeStatesResponse;
  public updateSignatureData: string;
  public modalData: BaseModalConfig = {
    useWrapperOnly: true,
    useWrapperEmpty: true,
    action: {
      allowClose: true
    }
  } as BaseModalConfig;
  private formGroup: DisclosureModalFormGroup = new DisclosureModalFormGroup(
    {}
  );
  private subscriptions: Subscription[] = [];
  private isScrolledDown = true;
  private isFormValid = true;
  private application: ApplicationData;
  constructor(
    private activeModal: NgbActiveModal,
    public googleAnalytics: GoogleAnalytics,
    public applicationDataService: ApplicationDataService,
    public environment: Environment
  ) {}

  public get signatureType(): typeof SignatureType {
    return SignatureType;
  }

  public get outputSignatures(): SignatureModel[] {
    return this.signatureChildren.map(x => x.signatureModel);
  }

  public get isBtnDisabled(): boolean {
    return (
      !this.isFormValid &&
      (!this.isScrolledDown ||
        !this.consentModal.content[0]?.agreement?.outOfScroll)
    );
  }

  async ngOnInit(): Promise<void> {
    this.application = this.applicationDataService.getApplication();
    const documentContentText = this.consentModal?.documentContent
      ?.documentText;
    if (
      documentContentText &&
      this.consentModal?.documentContent.documentType === 'LoanAgreement'
    ) {
      const docSigned = documentContentText?.split(
        '<div id="loanAgreementConsents"></div>'
      )[1];
      const signedData = docSigned?.split('<style>')[0];
      this.updateSignatureData = signedData;
      this.contentDocumentText = documentContentText?.replace(
        this.updateSignatureData,
        '</div>'
      );
    } else {
      this.contentDocumentText = documentContentText;
    }
    // If ContentStack Multiple Group has only one instance - it's loaded as Object but not Array
    if (
      this.consentModal?.content != null &&
      !Array.isArray(this.consentModal.content)
    ) {
      this.consentModal.content = [this.consentModal.content];
    }
    if (this.consentModal.content[0]?.disclosureText) {
      this.consentModal.content[0].disclosureText = this.consentModal.content[0]?.disclosureText?.replaceAll(
        '{environment}',
        this.environment?.bau?.url
      );
    }
    this.consentModal.content = this.filterConsentsByFundingMethod(
      this.consentModal?.content
    );
    if (this.consentModal.isScrollDownMandatory) {
      this.isScrollTillEndOfModal = this.consentModal?.isScrollTillEndOfModal;
      if (this.consentModal.isScrollLinkVisible) {
        const scrollDownLabelParts = this.consentModal.labels.scrollDownLabel.split(
          '{link}'
        );
        this.scrollDownFirstLabel = scrollDownLabelParts[0];
        this.scrollDownSecondLabel = scrollDownLabelParts[1];
      } else {
        this.scrollDownFirstLabel = this.consentModal.labels.scrollDownLabel.replace(
          '{link}',
          this.consentModal.labels.scrollDownLinkLabel
        );
      }
    }
    if (this.consentModal?.date?.isDateVisible) {
      this.date = moment()
        .tz('America/Chicago')
        .format('MM/DD/YYYY');
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isSeparateLineVisible = this.isScrollNeeded();
      this.isScrollDownMandatory = this.isScrollDownPhraseNeeded();
      this.isFormValid = this.formGroup.valid;
      const validSubscription$ = this.formGroup.statusChanges.subscribe(
        status => (this.isFormValid = status === 'VALID')
      );
      this.subscriptions.push(validSubscription$);
      if (this.isScrollDownPhraseNeeded()) {
        this.isScrolledDown = false;
        const scrollSubscription$ = this.scrollbarRef.scrolled?.subscribe(
          (e: any) => {
            if (e.target.scrollHeight - (e.target.scrollTop + e.target) <= 1) {
              this.isScrolledDown = true;
            }
          }
        );
        this.subscriptions.push(scrollSubscription$);
      }
      const modalClickAnchorTags = document.querySelectorAll(
        "[data-ga4='modal-anchor-click']"
      );
      const anchorElements: Element[] = Array.from(modalClickAnchorTags);
      for (let item of anchorElements) {
        item.addEventListener('click', () => {
          this.raiseGA4modalClickEvent(
            item.textContent.toString(),
            ModalClickAction.Cta_Click
          );
        });
      }
    }, 3000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public filterConsentsByFundingMethod(
    consents: ConsentModalContent[]
  ): ConsentModalContent[] {
    //filter consent
    return consents
      ?.map(consentModalContent => {
        if (
          consentModalContent.fundingMethod != null &&
          !Array.isArray(consentModalContent.fundingMethod)
        ) {
          consentModalContent.fundingMethod = [
            consentModalContent.fundingMethod
          ];
        }

        return consentModalContent;
      })
      .filter(consentModalContent => {
        if (
          consentModalContent.fundingMethod == null ||
          consentModalContent.fundingMethod?.includes(
            this.application.form?.fundMethod
          )
        ) {
          return consentModalContent;
        }
      });
  }
  public getSignatureValue(agreement: ConsentModalAgreement): any {
    if (!this.inputSignatures) {
      return null;
    }
    const controlName = SignatureHelper.getSignatureControlName(agreement);
    const signature = this.inputSignatures.filter(
      x => x.controlName === controlName
    );
    return signature?.length > 0 ? signature[0].signatureValue : null;
  }
  public getSignatureControlName(agreement: ConsentModalAgreement): any {
    return SignatureHelper.getSignatureControlName(agreement);
  }
  public printDisclosure(): void {
    this.raiseGA4modalClickEvent(this.consentModal.labels.savePrintLabel);
    const url = this.msieversion() ? `${window.location.origin}/blank` : '';
    const printWindow = window.open(url, '_blank');
    const tempHeader = document.createElement('div');
    tempHeader.innerHTML = this.consentModal.header;
    const header = tempHeader.textContent || tempHeader.innerText;
    let documentContent = document.getElementById('disclosureModalContent')
      .innerHTML;
    printWindow.document.open();
    printWindow.document.write(
      `<html><head><title>${header}</title></head><body onload="window.print()">${documentContent}</body></html>`
    );
    printWindow.document.close();
  }

  public submit(): void {
    this.raiseGA4modalClickEvent(this.consentModal.labels.buttonLabel);
    this.formGroup.showValidationErrors();
    if (!this.formGroup.valid) {
      setTimeout(() => this.scrollToFirstInvalidControll(), 0);
      return;
    }
    this.submitClick.emit({
      signatureModel: this.outputSignatures
    });
    this.activeModal.close();
  }
  public cancel(): void {
    this.raiseGA4modalClickEvent(this.consentModal.labels.cancelButtonLabel);
    this.cancelClick.emit();
    this.activeModal.close();
  }
  public scrollDown(): void {
    if (this.isScrollTillEndOfModal) {
      document
        .getElementsByClassName('agreement-section-end')[0]
        .scrollIntoView();
    } else {
      document.getElementsByClassName('agreement-section')[0].scrollIntoView();
    }
    this.raiseGA4modalClickEvent(this.consentModal.labels.scrollDownLinkLabel);
  }
  private msieversion(): string {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      return ua.substring(msie + 5, ua.indexOf('.', msie));
    }
    return null;
  }
  private isScrollNeeded(): boolean {
    return this.scrollbarRef.state?.isVerticallyScrollable;
  }
  private isScrollDownPhraseNeeded(): boolean {
    return this.isScrollNeeded() && this.consentModal.isScrollDownMandatory;
  }
  private scrollToFirstInvalidControll(): void {
    this.contentContainer.nativeElement
      .getElementsByClassName('ng-invalid ng-touched ng-dirty invalid')[0]
      ?.scrollIntoView();
  }
  public raiseGA4modalClickEvent(
    linkText: string,
    action: ModalClickAction = ModalClickAction.Click
  ) {
    this.googleAnalytics.clickModalEvent({
      link_text: linkText,
      action: action,
      modal_name: this.consentModal.header
    });
  }
}
