import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Environment } from '@environment/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { catchError, delay, retryWhen, takeWhile, tap } from 'rxjs/operators';
import {
  AbTestingData,
  ApplicationData,
  ApplicationFlow,
  ApplicationForm
} from './application';
import { ApplicationSearchByIdModel } from './getting-started/resume-modal/resume-modal';
import { BrowserWindow } from '@core/cms/resolvers/cms-page-content.resolver';
import { EnvironmentService } from '@environment/environment.service';
import { CookieEnums, CookieService } from '@core/cookie/cookie-service';
import { LocationProxy } from '@core/location-proxy/location-proxy.service';

export enum StartOption {
  StartNewOnly = 'StartNewOnly',
  ResumeOnly = 'ResumeOnly',
  ResumeOrStartNew = 'ResumeOrStartNew',
  NoOptions = 'NoOptions',
  RedirectToBau = 'RedirectToBau',
  RedirectFormerRefinanceSignIn = 'RedirectFormerRefinanceSignIn',
  RedirectToLegacy = 'RedirectToLegacy'
}

export interface StartOptionsResponse {
  startOptions: StartOption;
  applicationId?: string | null;
  customerId?: number | null;
  customerIdSignature?: string;
  phoneNumberLast4?: string;
  abTesting?: AbTestingData[];
  redirectUrl?: string;
}

export interface StartOptionsRequest {
  applicationFlow: string;
  brand: string;
  socialSecurityNumber?: string;
  email: string;
  stateCode?: string;
  leadId?: string;
  dateOfBirth: Date;
  abTesting?: AbTestingData[];
}

export interface PartnerOfferIds {
  leadId: string;
  offerId?: string;
  partnerName?: string;
}

export interface CreateApplicationRequest {
  brand: string;
  applicationFlow: string;
  customerId?: string;
  customerSignature?: string;
  partnerLeadId?: string;
  partner?: PartnerOfferIds;
  form: ApplicationForm;
  recaptchaToken?: string;
  abTesting?: AbTestingData[];
}

export interface CreateApplicationResponse {
  token: string;
}

export interface AppendDeviceRequest {
  iovationSignature?: string;
  iovationRequestType?: string;
  queryString?: string;
  referrerUrl?: string;
  GCID?: string;
  neuroID?: string;
}

export interface ReplaceApplicationRequest {
  sections: ReplaceApplicationSection[];
}

export interface ReplaceApplicationSection {
  path: string;
  value: any;
}

@Injectable({ providedIn: 'root' })
export class ApplicationApi {
  constructor(
    private environment: Environment,
    private httpClient: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private locationProxy: LocationProxy,
    private cookieService: CookieService,
    @Inject('window') private window: BrowserWindow,
    private environmentService: EnvironmentService
  ) {
    if (!this.environment.production) {
      this.window.getApplication = this.addConsoleRetrieveApp.bind(this);
    }
  }
  public startOptions(
    request: StartOptionsRequest
  ): Observable<StartOptionsResponse> {
    return this.httpClient
      .post<StartOptionsResponse>(
        `${this.environment.brandApi.url}/api/v1/application/start-options`,
        request
      )
      .pipe(
        retryWhen((error: any) => {
          let retryCount = 0;
          return error.pipe(
            delay(this.environment.httpRetry.retryTimeout),
            takeWhile(() => retryCount < this.environment.httpRetry.retryCount),
            tap(
              () => retryCount++,
              () => {},
              () => {
                this.navigateToErrorPage();
                throw new Error();
              }
            )
          );
        })
      );
  }

  public startOptionsByID(
    searchParams: ApplicationSearchByIdModel
  ): Observable<StartOptionsResponse> {
    return this.httpClient
      .post<StartOptionsResponse>(
        `${this.environment.brandApi.url}/api/v1/application/start-options-by-id`,
        searchParams
      )
      .pipe(
        retryWhen((error: any) => {
          let retryCount = 0;
          return error.pipe(
            delay(this.environment.httpRetry.retryTimeout),
            takeWhile(() => retryCount < this.environment.httpRetry.retryCount),
            tap(
              () => retryCount++,
              () => {},
              () => {
                throw new Error();
              }
            )
          );
        })
      );
  }

  /* istanbul ignore next */
  public create(
    request: CreateApplicationRequest
  ): Observable<CreateApplicationResponse> {
    return this.httpClient
      .post<CreateApplicationResponse>(
        `${this.environment.brandApi.url}/api/v1/application`,
        request
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          if (httpError?.error?.message?.includes('is not Serviced')) {
            throw error;
          } else {
            this.navigateToErrorPage();
          }
          return throwError(error);
        })
      );
  }

  public createCustomer(
    request: CreateApplicationRequest,
    flow?: ApplicationFlow
  ): Observable<CreateApplicationResponse> {
    const authTokens = this.getAuthTokens();
    const url =
      flow === ApplicationFlow.Refi
        ? `${this.environment.brandApi.url}/api/v1/refinanceApplication`
        : `${this.environment.brandApi.url}/api/v1/formerApplication`;

    return this.httpClient
      .post<CreateApplicationResponse>(url, request, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          jwtToken: authTokens.jwtToken,
          riseToken: authTokens.riseToken
        })
      })
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          const bauLoginUrl = `${this.environment.bau.secureUrl}/${this.environment.navigation.login}`;
          if (httpError.status === 401) {
            this.locationProxy.assign(bauLoginUrl);
          } else if (httpError.status !== 429) {
            this.navigateToErrorPage();
          }
          throw error;
        })
      );
  }

  public append(request: ApplicationForm): Observable<any> {
    return this.httpClient
      .patch(`${this.environment.brandApi.url}/api/v1/application`, request, {
        observe: 'response'
      })
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          if (httpError?.error?.message?.includes('is not Serviced')) {
            throw error;
          } else if (
            httpError.status !== 406 &&
            (httpError.status !== 400 ||
              httpError.error['message'] !== 'Invalid bank information.')
          ) {
            this.navigateToErrorPage();
          }
          return throwError(error);
        })
      );
  }

  /* istanbul ignore next */
  public appendDevice(request: AppendDeviceRequest): Observable<any> {
    return this.httpClient.patch(
      `${this.environment.brandApi.url}/api/v1/append-device`,
      request
    );
  }

  public get(): Observable<ApplicationData> {
    return this.httpClient
      .get<ApplicationData>(
        `${this.environment.brandApi.url}/api/v1/application`
      )
      .pipe(
        catchError((error: any) => {
          this.navigateToErrorPage();

          return throwError(error);
        })
      );
  }

  public replace(request: ReplaceApplicationRequest): Observable<any> {
    return this.httpClient
      .patch(
        `${this.environment.brandApi.url}/api/v1/application/replace`,
        request,
        {
          observe: 'response'
        }
      )
      .pipe(
        catchError((error: any) => {
          this.navigateToErrorPage();
          return throwError(error);
        })
      );
  }

  /* istanbul ignore next */
  public submit(): Observable<HttpResponse<any>> {
    return this.httpClient.patch<HttpResponse<any>>(
      `${this.environment.brandApi.url}/api/v1/application/submit`,
      {},
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        observe: 'response'
      }
    );
  }

  /* istanbul ignore next */
  public signTermsAndConditions(): Observable<HttpResponse<any>> {
    return this.httpClient.patch<HttpResponse<any>>(
      `${this.environment.brandApi.url}/api/v1/application/sign-terms-and-conditions`,
      {},
      {
        observe: 'response'
      }
    );
  }

  /* istanbul ignore next */
  public initiateBooking(): Observable<HttpResponse<any>> {
    return this.httpClient.patch<HttpResponse<any>>(
      `${this.environment.brandApi.url}/api/v1/application/initiate-booking`,
      {},
      {
        observe: 'response'
      }
    );
  }

  public navigateToErrorPage(): void {
    this.modalService.dismissAll();
    this.router.navigate(['/error']);
  }

  /* istanbul ignore next */
  public addConsoleRetrieveApp(): Promise<any> {
    return lastValueFrom(
      this.httpClient.get<ApplicationData>(
        `${this.environment.brandApi.url}/api/v1/application`
      )
    );
  }

  public getInitialAppFlow(brand: string): Observable<any> {
    let environmentAppconfigApiUrl: string = this.environmentService.getEnvironmentAppConfigUrl();

    return this.httpClient
      .get<any>(
        `${environmentAppconfigApiUrl}/ABTest/segment/${brand}/ElasticAppFlowImprovements`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Api-Version': 'v2'
          }),
          observe: 'body'
        }
      )
      .pipe(
        catchError((error: any) => {
          this.navigateToErrorPage();

          return throwError(error);
        })
      );
  }

  private getAuthTokens(): { riseToken: string; jwtToken: string } {
    const accessToken = this.cookieService.get(CookieEnums.jwtToken) || '';
    const riseToken = this.cookieService.get(CookieEnums.riseToken) || '';

    return { jwtToken: accessToken, riseToken };
  }
}
