<div class="prequal-aproval-page-container">
  <h2 id="prequalApprovalPageSubTitle" class="page-subtitle">
    {{ subHeader }}
  </h2>
  <div>
    <div
      *ngIf="isControl"
      [ngClass]="
        isRise
          ? 'rise-offer-container ecl-rise-color-text'
          : 'ecl-font-section-header-color-large offer-container'
      "
    >
      <span id="offerAmount">
        {{ approvalAmount | currency: 'USD':'symbol':'1.0-0' }}
      </span>
    </div>
    <div
      *ngIf="!isControl"
      class="ecl-font-section-header-color-large non-control-amount"
    >
      <span
        id="offerAmountNonControl"
        [ngClass]="{ 'offer-container': decimalValue === '1.2-2' }"
      >
        {{ approvalAmount | currency: 'USD':'symbol':'1.0-0' }}
      </span>
    </div>
    <span id="disclaimer" class="disclaimer-txt">{{ content.disclaimer }}</span>
  </div>
  <div
    *ngIf="!isControl"
    id="prequalApprovalPageAbTest"
    class="page-content"
    [innerHTML]="nextStep"
  ></div>
  <div
    *ngIf="!isCounterOffer"
    id="prequalApprovalPageContent"
    class="page-content"
  >
    {{ offerContent }}
  </div>
  <div
    *ngIf="isCounterOffer"
    id="prequalApprovalPageContentToCounterOffer"
    class="page-content counter"
    [innerHTML]="counterOfferSubText | safeHtml"
  ></div>
  <form [formGroup]="form" (submit)="onSubmit()">
    <app-consents
      #consentsComponent
      [formGroup]="form"
      [consentSectionItems]="consentsSection"
    ></app-consents>

    <div class="continue-button-container">
      <button
        type="submit"
        googleAnalyticsClick="PrequalOffer-Continue"
        id="prequalContinueButton"
        class="ecl-button-primary"
      >
        {{ content.buttons.continue }}
      </button>
    </div>
  </form>
</div>

<app-debug-hud>
  <button (click)="debugHud.toggleDmUser(); update()">
    {{ debugHud.isDMuser ? 'Non-Direct Mail' : 'Direct Mail' }}
  </button>
  <button *ngIf="formContainsConsents" (click)="debugHudSignAndSubmit()">
    sign and submit
  </button>
</app-debug-hud>
