import { Component, OnInit } from '@angular/core';
import { ProgressBarService } from '@core/progress-bar/progress-bar.service';
import { ProgressBarInfo } from './modal/progress-bar.modal';
@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ApplicationProgressStatusComponent implements OnInit {
  applicationProgress: Array<ProgressBarInfo>;
  constructor(private progressBarService: ProgressBarService) {}
  ngOnInit() {
    this.progressBarService.getProgressBarStatus().subscribe((res:Array<ProgressBarInfo>) => {
      this.applicationProgress = res;
    });
  }
}
