import { Injectable } from '@angular/core';

import { ApplicationData } from '@application/application';
import { ApplicationApi } from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LogRocketService } from '../core/logrocket/logrocket.service';
import { NeuroIdService } from '../core/neuro-id/neuro-id.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import {
  GoogleAnalytics,
  AbTestingData
} from '@core/google-analytics/googleanalytics.service';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';

@Injectable({ providedIn: 'root' })
export class ApplicationResolver {
  constructor(
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private logrocketService: LogRocketService,
    private neuroIdService: NeuroIdService,
    private appInsightsService: AppInsightsService,
    private sessionStorageService: SessionStorageService,
    public googleAnalytics: GoogleAnalytics
  ) {}

  public resolve(): Observable<ApplicationData> {
    const authToken = this.sessionStorageService.getItem(
      SessionStorageKeys.authorizationToken
    );
    if (authToken) {
      return this.applicationApi.get().pipe(
        tap((application: ApplicationData) => {
          this.applicationDataService.merge(application);
          this.logrocketService.addSearchableFields(application);
          this.neuroIdService.sendUserId(application.id);
          this.neuroIdService.setVariable(
            'funnel',
            application.applicationFlow
          );
          let abTestingData: AbTestingData[] = [];
          this.googleAnalytics.resetGoogleVariableValue('abTesting');

          application?.abTesting?.forEach(test => {
            this.appInsightsService.trackTrace(
              { message: test.testName },
              test
            );

            this.logrocketService.addAbTestingData(
              test.testName,
              test.segmentName
            );

            abTestingData.push({
              testName: test.testName,
              segmentName: test.segmentName
            });
          });

          this.googleAnalytics.setGoogleTagManagerVariables({
            abTesting: abTestingData
          });

          return application;
        })
      );
    }
  }
}
