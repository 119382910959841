import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  firstValueFrom,
  lastValueFrom
} from 'rxjs';
import { share, shareReplay } from 'rxjs/operators';

import { Environment } from './environment';
import { brandConfiguration, brandRouting } from './environment.models';
import { PlatformConfigFeatures } from '@core/configuration/configuration.service';
import {
  SessionStorageKeys,
  SessionStorageObjects
} from '@core/session-storage/session-storage.service';
import { AbTestingData } from '@application/application';
import { ABTestElasticTestSegment } from '@core/local-storage/local-storage.service';
@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private obs: Promise<Observable<Environment>>;
  private routingObs: Observable<any>;
  public isDefaultApplicationFlowSubject = new BehaviorSubject(true);
  public isDefaultApplicationFlow = true;
  public elasticTestSegment: AbTestingData;
  private environmentName: string = document
    .querySelector('meta[name="environment"]')
    ?.getAttribute('content');
  private baseURL: string = window.location.origin;

  constructor(private http: HttpClient) {
    let brands = brandConfiguration;

    if (this.baseURL.includes('localhost')) {
      // this.baseURL = 'https://dev02-apply.lle.risecredit.com';
      this.baseURL = 'https://dev03-ams.lle.risecredit.com/';
      this.environmentName = 'DEV03';
    }

    this.obs = new Promise<string>((resolve, reject) => {
      for (const [key, value] of Object.entries(brands)) {
        if (this.baseURL.includes(key)) {
          resolve(value);
          this.loadStyle(key);
          this.loadRouting(key);
          return;
        }
      }
      resolve('ElasticUI');
    }).then(value => {
      return http
        .get<Environment>(
          `${this.baseURL}/originations/${this.environmentName}/appconfigapi/api/UIConfiguration?path=${value}`,
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Api-Version': 'v2'
            }),
            observe: 'body'
          }
        )
        .pipe(share());
    });
  }

  public getEnvironment(): Promise<Observable<Environment>> {
    return this.obs;
  }

  public getEnvironmentAppConfigUrl(): string {
    return `${this.baseURL}/originations/${this.environmentName}/appconfigapi/api`;
  }

  public getRouting(): Observable<any> {
    return this.routingObs;
  }

  private loadStyle(brand: string): void {
    const head = document.getElementsByTagName('head')[0];

    const styleName = `${brand}.css`;
    const themeLink = document.getElementById('brand-theme') as HTMLLinkElement;
    const faviconName = `favicon-${brand}.ico`;
    const favIconLink = document.getElementById(
      'brand-favicon'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = document.createElement('link');
      style.id = 'brand-theme';
      style.rel = 'stylesheet';
      style.href = styleName;

      head.appendChild(style);
    }
    if (favIconLink) {
      favIconLink.href = faviconName;
    } else {
      const style = document.createElement('link');
      style.id = 'brand-favicon';
      style.rel = 'icon';
      style.type = 'image/x-icon';
      style.href = `/favIcons/${faviconName}`;

      head.appendChild(style);
    }
  }

  public async loadRouting(
    brand: string,
    featureName: PlatformConfigFeatures = PlatformConfigFeatures.ApplicationFlow,
    resumeApp = false
  ): Promise<void> {
    let brandId = brandRouting[brand];

    this.elasticTestSegment = this.elasticTestSegment = {
      segmentName: 'Control',
      testName: ABTestElasticTestSegment.TestName
    };
    let localFlow = localStorage.getItem(
      SessionStorageKeys.isElasticImprovementFlow
    );
    let sessionFlow = sessionStorage.getItem(
      SessionStorageKeys.isElasticImprovementFlow
    );
    if (sessionFlow === 'true' || localFlow === 'true') {
      featureName =
        PlatformConfigFeatures.GetElasticImprovementsApplicationFlow;
      this.elasticTestSegment = {
        segmentName: ABTestElasticTestSegment.SegmentName,
        testName: ABTestElasticTestSegment.TestName
      };
      this.isDefaultApplicationFlowSubject.next(false);
      this.isDefaultApplicationFlow = false;

      if(!sessionFlow){
        sessionStorage.setItem(
          SessionStorageKeys.isElasticImprovementFlow,
          (!this.isDefaultApplicationFlow).toString()
        );
      }

    } else {
      if (!resumeApp && sessionFlow === null) {
        const elasticTestSegmentObs = this.http
          .get<any>(
            `${this.baseURL}/originations/${this.environmentName}/appconfigapi/api/ABTest/segment/${brand}/ElasticAppFlowImprovements`,
            {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Api-Version': 'v2'
              }),
              observe: 'body'
            }
          )
          .pipe(shareReplay(1));
        await lastValueFrom(elasticTestSegmentObs).then(res => {
          this.elasticTestSegment = res;
          if (res && res.segmentName == 'InitialImprovements') {
            this.isDefaultApplicationFlowSubject.next(false);

            this.isDefaultApplicationFlow = false;
            featureName =
              PlatformConfigFeatures.GetElasticImprovementsApplicationFlow;
          }
          localStorage.setItem(
            SessionStorageKeys.isElasticImprovementFlow,
            (!this.isDefaultApplicationFlow).toString()
          );
          sessionStorage.setItem(
            SessionStorageKeys.isElasticImprovementFlow,
            (!this.isDefaultApplicationFlow).toString()
          );
        }),
          (error: any) => {
            this.isDefaultApplicationFlowSubject.next(false);
            this.isDefaultApplicationFlow = false;
            featureName =
              PlatformConfigFeatures.GetElasticImprovementsApplicationFlow;
          };
      }
    }

    this.routingObs = this.http
      .get<any>(
        `${this.baseURL}/originations/${this.environmentName}/appconfigapi/api/PlatformConfig?brandId=${brandId}&featureName=${featureName}`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Api-Version': 'v2'
          }),
          observe: 'body'
        }
      )
      .pipe(shareReplay(1));
    let applicationFlows = await firstValueFrom(this.routingObs);
    let jsonObj = JSON.stringify(applicationFlows);
    sessionStorage.setItem(SessionStorageObjects.applicationFlows, jsonObj);
  }
}
