<div class="verify-info-page-container">
  <h2
    *ngIf="pageSubTitle"
    id="pageSubTitleLabel"
    class="page-subtitle ecl-font-header-medium"
  >
    {{ pageSubTitle }}
  </h2>

  <ng-container *ngIf="content.personal">
    <div class="title-container">
      <h2
        class="verify-section-header ecl-font-section-header-color-xsmall display-heading-xs"
        id="personalInfoTitle"
      >
        {{ content.personal.header }}
      </h2>
      <span *ngIf="content.personal.showEditButton">
        <a
          class="ecl-font-link-light-bg"
          (click)="onEdit()"
          id="personalInformationEdit"
          >Edit
        </a>
      </span>
    </div>
    <div class="page-row row">
      <div class="col ecl-font-body-bold" id="nameLabel">
        {{ content.personal.labels.name }}
      </div>
      <div class="col text-right" id="nameTextContainer">
        {{ customer.firstName }} {{ customer.lastName }}
        {{ customer.nameSuffix }}
      </div>
    </div>

    <div class="page-row row">
      <div class="col ecl-font-body-bold" id="dobLabel">
        {{ content.personal.labels.dob }}
      </div>
      <div
        class="col text-right cobrowse-masked"
        id="dobTextContainer"
        data-private
      >
        {{ customer.dateOfBirth | date: 'MM/dd/yyyy' }}
      </div>
    </div>

    <div class="page-row row">
      <div class="col ecl-font-body-bold" id="ssnLabel">
        {{ content.personal.labels.ssn }}
      </div>
      <div
        class="col text-right cobrowse-masked"
        id="ssnTextContainer"
        data-private
      >
        ***-**-{{ customer.ssn }}
      </div>
    </div>
    <hr class="section-separater" />
  </ng-container>
  <ng-container *ngIf="content.contactInformation">
    <div class="title-container">
      <h2
        class="verify-section-header ecl-font-section-header-color-xsmall display-heading-xs"
        id="contactInfoTitle"
      >
        {{ content.contactInformation.header }}
      </h2>
      <span *ngIf="content.contactInformation.showEditButton">
        <a
          class="ecl-font-link-light-bg"
          (click)="onEdit()"
          id="contactInformationEdit"
          >Edit
        </a>
      </span>
    </div>
    <div class="page-row row">
      <div class="col ecl-font-body-bold" id="addresLabel">
        {{ content.contactInformation.labels.address }}
      </div>
      <div class="col text-right" id="addressTextContainer">
        {{ customer.addressLine1 }}
        <span *ngIf="customer.addressLine2?.trim()">
          <br />
          {{ customer.addressLine2 }}</span
        >
        <br />{{ customer.city }}, {{ customer.stateCode }}
        {{ customer.zipCode }}
      </div>
    </div>

    <div class="page-row row">
      <div class="col ecl-font-body-bold" id="emailLabel">
        {{ content.contactInformation.labels.email }}
      </div>
      <div class="col text-right" id="emailTextContainer">
        {{ maskedEmail }}
      </div>
    </div>

    <div class="page-row row">
      <div class="col ecl-font-body-bold" id="phoneLabel">
        {{ content.contactInformation.labels.mobile }}
      </div>
      <div class="col text-right" id="mobilePhoneNumberText">
        {{ customer.phoneNumber | mask: '(000) 000-0000' }}
      </div>
    </div>

    <hr class="section-separater" />
  </ng-container>
  <ng-container *ngIf="content.checkingInformation">
    <div class="title-container">
      <h2
        class="verify-section-header ecl-font-section-header-color-xsmall display-heading-xs"
        id="checkingInformationTitle"
      >
        {{ content.checkingInformation.header }}
      </h2>
      <span *ngIf="content.checkingInformation.showEditButton">
        <a
          class="ecl-font-link-light-bg"
          (click)="onEdit()"
          id="checkingInformationEdit"
          >Edit
        </a>
      </span>
    </div>
    <div class="page-row row">
      <div class="col ecl-font-body-bold" id="routingValueLabel">
        {{ content.checkingInformation.labels.routing }}
      </div>
      <div class="col text-right" id="routingValueContainer">
        {{ customer.routingNumber }}
      </div>
    </div>

    <div class="page-row row">
      <div class="col ecl-font-body-bold" id="accountValueContainer">
        {{ content.checkingInformation.labels.account }}
      </div>
      <div
        class="col text-right cobrowse-masked"
        id="accountTextContainer"
        data-private
      >
        XXXXXXXXXX{{ customer.bankAcctNumber }}
      </div>
    </div>
  </ng-container>
  <form [formGroup]="form" (submit)="onSubmit()">
    <app-consents
      #consentsComponent
      [formGroup]="form"
      [consentSectionItems]="content.consentsSection"
    ></app-consents>
    <div *ngIf="stateLicenses">
      <hr class="section-separater" />
      <p class="ecl-font-body-bold">{{ licenseSubtext }}</p>
      <div class="state-license-wrapper-div">
        <a
          class="state-license-link"
          rel="noopener"
          *ngFor="let license of stateLicenses.file"
          href="{{ license.url }}"
          target="_blank"
        >
          <img src="{{ license.url }}" alt="{{ license.title }}"
        /></a>
      </div>
    </div>

    <div *ngIf="policySection" class="policy-section">
      <div *ngFor="let policy of policySection">
        <div [innerHTML]="policy.defaultContent"></div>
      </div>
    </div>

    <div class="ecl-button-hgroup ecl-wrap-reverse btn-container">
      <button
        focusOnError
        id="verifyContinueButton"
        type="submit"
        class="ecl-button-primary"
        googleAnalyticsClick="VerifyInformation-Continue"
      >
        {{ content.buttons.continue }}
      </button>
      <button
        id="verifyEditButton"
        type="button"
        (click)="onEdit()"
        class="ecl-button-secondary"
        googleAnalyticsClick="VerifyInformation-Edit"
      >
        {{ content.buttons.edit }}
      </button>
    </div>
  </form>
</div>
