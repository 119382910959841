import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { UnderwritingApprovalFormGroup } from '../underwritng-approval/underwriting-approval.form';
import { ApplicationDataService } from '@application/application.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import {
  ApplicationForm,
  MaritalStatusModel,
  MarriedSpouseModel
} from '@application/application';
import { lastValueFrom } from 'rxjs';
import { OtpService } from '@core/otp/otp.service';
import { Married } from '../underwritng-approval/married-form/married.form';
import { ApplicationApi } from '@application/application.api';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationMessagesError } from '@elevate/forms';
import { EmailConfirmation } from './email-confirmation.content';
import { Select, TextInput } from '@elevate/ui-components';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { EmailConfirmationDebugService } from '../underwritng-approval/debug-hud/email-confirmation-debug.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
  providers: [UnderwritingApprovalFormGroup],
  encapsulation: ViewEncapsulation.None
})
export class EmailConfirmationComponent implements OnInit {
  public emailVerified: boolean;
  public isWisconsin: boolean;
  public content: EmailConfirmation;
  public brand: string;
  public hideAdditionalQuestions: boolean;

  public emailCodeConfig: TextInput = {
    id: 'emailConfirmationCodeInput',
    required: 'true',
    attributes: {
      'data-private': 'redact',
      'data-nid-target': 'emailConfirmationCode'
    },
    placeholder: 'ex: PqnZz9',
    addGenesysCobrowseMaskingClass: 'true'
  };

  public maritalStatusConfig: Select = {
    id: 'maritalStatusSelect',
    disabled: 'false',
    value: '',
    visibleOptions: 2,
    options: [],
    attributes: {
      'data-nid-target': 'maritalStatus'
    }
  };

  constructor(
    private cmsPageContentService: CmsPageContentService,
    public form: UnderwritingApprovalFormGroup,
    private applicationDataService: ApplicationDataService,
    private loadingService: LoadingModalService,
    private otpService: OtpService,
    private applicationApi: ApplicationApi,
    private router: Router,
    private route: ActivatedRoute,
    private applicationFlowService: ApplicationFlowService,
    private debugService: EmailConfirmationDebugService
  ) {}
  ngOnInit() {
    this.content = this.route.snapshot.data.cmsContent
      .emailConfirmation as EmailConfirmation;
    this.cmsPageContentService.updatePageTitle(this.content.header);
    const application = this.applicationDataService.getApplication();
    this.brand = application.brand;
    const email = application.form.applicant.emails[0]?.address;
    this.content.subHeader = this.content.subHeader.replace('{email}', email);
    if (application.form.applicant.emails[0].status === 'Verified') {
      this.emailVerified = true;
    }
    this.maritalStatusConfig.options = [
      {
        value: MaritalStatusModel.Married,
        label: this.content.maritalStatus.maritalStatusSelect.optionMarried
      },
      {
        value: MaritalStatusModel.Unmarried,
        label: this.content.maritalStatus.maritalStatusSelect.optionNotMarried
      }
    ];
    this.maritalStatusConfig.placeholder = this.content.maritalStatus.maritalStatusSelect.placeholder;
    const stateCode =
      application.form.applicant.residences[0].address.stateCode;
    if (stateCode === 'WI') {
      this.isWisconsin = true;
      this.form.get('maritalStatus').enable();
    } else {
      this.form.get('maritalStatus').disable();
    }
  }

  public async populateConfirmationCode(): Promise<void> {
    await lastValueFrom(this.debugService.getConfirmationCode()).then(val => {
      this.form.get('confirmationCode').setValue(val.body.otpCode);
    });
  }

  public async onSubmit(): Promise<void> {
    this.form.showValidationErrors();
    if (!this.form.valid) {
      return;
    }

    this.loadingService.open();
    const confirmationCode = this.form.get('confirmationCode').value;
    let application: ApplicationForm = {};
    try {
      await lastValueFrom(
        this.otpService.verifyEmailCode({ otp: confirmationCode })
      );
      if (this.isWisconsin) {
        application = {
          applicant: {
            marital: this.constructMaritalObject()
          }
        };

        await lastValueFrom(this.applicationApi.append(application));
      }
      const nextPage = this.applicationFlowService.getContinuePath();
      this.router.navigate([nextPage]);
    } catch {
      this.loadingService.close();
      this.setConfirmationCodeInvalidError();
    }
  }

  public showAdditionalQuestions(value: string): void {
    this.hideAdditionalQuestions =
      value === MaritalStatusModel.Unmarried ? false : true;
  }

  private constructMaritalObject(): MarriedSpouseModel {
    const maritalValue = this.form.get('maritalStatus').value;

    const maritalResponse: MarriedSpouseModel = {
      status: maritalValue
    };
    if (this.form.get('maritalStatus').value === MaritalStatusModel.Married) {
      const married: Married = this.form.get('married').value;

      maritalResponse.spouseFirstName = married.spouseFirstName;
      maritalResponse.spouseLastName = married.spouseLastName;
      maritalResponse.spouseEmailAddress = this.form.get(
        'married.spouseEmailAddress'
      ).value;
    }
    return maritalResponse;
  }

  private setConfirmationCodeInvalidError(): void {
    this.form
      .get('confirmationCode')
      .setErrors(
        new ValidationMessagesError(
          'verificationCode',
          null,
          this.content.validationMessages.activationCodeNotValid
        )
      );
  }

  public async resendConfirmationCode(): Promise<void> {
    this.loadingService.open();

    const requestPayload = {
      channel: 'Email',
      brand: this.applicationDataService.getApplication().brand
    };
    try {
      await lastValueFrom(this.otpService.sendCode(requestPayload));
      this.loadingService.close();
    } catch {
      this.loadingService.close();
    }
  }
  public skipEmailVerification() {
    const nextPage = this.applicationFlowService.getContinuePath();
    this.router.navigate([nextPage]);
  }
}
