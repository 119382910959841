import { Injectable } from '@angular/core';
import { ProgressBarInfo } from '@application/progress-bar/modal/progress-bar.modal';
import { CmsService } from '@core/cms';
import { EnvironmentService } from '@environment/environment.service';
import {
  BehaviorSubject,
  lastValueFrom,
  Observable,
  ReplaySubject
} from 'rxjs';
import { AbTestingData } from '@application/application';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { LogRocketService } from '@core/logrocket/logrocket.service';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { Environment } from '@environment/environment';

@Injectable({ providedIn: 'root' })
export class ProgressBarService {
  public applicationProgress: BehaviorSubject<
    Array<ProgressBarInfo>
  > = new BehaviorSubject([]);
  public progressBarRequired = new BehaviorSubject<boolean>(false);
  constructor(
    private cmsService: CmsService,
    private environmentService: EnvironmentService,
    private environment: Environment,
    private appInsightsService: AppInsightsService,
    private logRocketService: LogRocketService,
    private googleAnalytics: GoogleAnalytics
  ) {}

  getProgressBarStatus(): Observable<Array<ProgressBarInfo>> {
    return this.applicationProgress;
  }

  public setABTestResultToDataLayer(abTestingData: AbTestingData) {
    let abTestingDataArr: AbTestingData[] = [];

    this.appInsightsService.trackTrace(
      { message: abTestingData.testName },
      abTestingData
    );

    this.logRocketService.addAbTestingData(
      abTestingData.testName,
      abTestingData.segmentName
    );

    abTestingDataArr.push(abTestingData);

    this.googleAnalytics.setGoogleTagManagerVariables({
      abTesting: abTestingDataArr
    });
  }

  async updateProgressBarStatus(progressBarName: string) {
    await lastValueFrom(this.cmsService.getEntries('shared')).then(async data => {
      data = Array.isArray(data) ? data[0] : data;
      const steps = [];
      let stepCompleted = false;
      data?.progressBar?.progressingBar?.forEach(element => {
        if (element.step.key === progressBarName) {
          element.step.status = 'inprogress';
          stepCompleted = true;
        } else if (!stepCompleted) {
          element.step.status = 'completed';
        } else {
          element.step.status = 'not-started';
        }
        steps.push(element.step);
      });
      this.applicationProgress.next(steps);
      this.progressBarRequired.next(true);      
    });
  }
  public resetProgressBar(): void {
    this.progressBarRequired.next(false);
  }
}
