import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  AfterViewInit
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  Data,
  NavigationEnd,
  NavigationStart,
  Router
} from '@angular/router';
import { UserInactivityStatusService } from '@core/inactivity-module/user-inactivity.service';
import { LocationProxy } from '@core/location-proxy/location-proxy.service';
import {
  GoogleAnalytics,
  GtagLinkData,
  GtagModalData,
  ModalClickAction
} from '@core/google-analytics/googleanalytics.service';
import { map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable, forkJoin, lastValueFrom, of } from 'rxjs';
import { CmsService } from '@core/cms';
import { Environment } from '@environment/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { Cobrowse } from '@elevate/layout';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { ApplicationDataService } from '@application/application.service';
import { UnderwritingApiService } from '@application/underwriting/underwriting-api.service';
import {
  SessionStorageService,
  SessionStorageObjects
} from '@core/session-storage/session-storage.service';
import { RestrictNavigationModalComponent } from '@application/restrict-navigation/restrict-navigation-modal/restrict-navigation-modal.component';
import { CobrowseConfig, CobrowseContent } from '@elevate/cobrowse';
import { ConfigurationService } from '@core/configuration/configuration.service';
import { BannerService } from './banner/banners.service';
import { Banner } from './models/banner.model';
import { Lender as lender } from '@application/consents/consents.content';
import { StateEligibilityService } from '@application/getting-started/state-eligibility/state-eligibility.service';
import { CookieService } from '@core/cookie/cookie-service';
import { ProgressBarService } from '@core/progress-bar/progress-bar.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, AfterViewInit {
  private cmsContent$: Observable<any>;

  public cookieNotificationContent$: Observable<any>;

  public footerContent$: Observable<any>;
  public rawHeaderCms$: Observable<any>;
  public fiveDayRiskFreeGuarantee$: Observable<any>;
  public showCookieNotification: boolean;
  public banners$: BehaviorSubject<Array<Banner>>;
  public cobrowseHtmlContent: CobrowseContent;
  public cobrowseConfig: CobrowseConfig;
  public isCobrowseEnabled: boolean = false;
  public previousPageDetails: string;
  public onCytPage = false;
  public cobrowse: Cobrowse;
  public lenderCode: string;
  public stateCode: string;
  public isProgressBarRequired: boolean;

  constructor(
    public router: Router,
    public environment: Environment,
    public modalService: NgbModal,
    public titleService: Title,
    public route: ActivatedRoute,
    public googleanalytics: GoogleAnalytics,
    public locationProxy: LocationProxy,
    public userInactivityService: UserInactivityStatusService,
    public cmsService: CmsService,
    public applicationFlowService: ApplicationFlowService,
    public loadingService: LoadingModalService,
    public sessionStorageService: SessionStorageService,
    public appDataService: ApplicationDataService,
    public underwritingService: UnderwritingApiService,
    public configurationService: ConfigurationService,
    public bannerService: BannerService,
    public stateEligibilityService: StateEligibilityService,
    public cookieService: CookieService,
    private progressBarService: ProgressBarService,
    @Inject('window') public window: Window
  ) {
    this.cmsContent$ = this.cmsService
      .getEntries('layout')
      .pipe(shareReplay(1));

    this.cookieNotificationContent$ = this.cmsContent$.pipe(
      map(res => {
        res.cookieNotification.content = res.cookieNotification.content?.replaceAll(
          '{environment}',
          this.environment.bau.url
        );
        return res.cookieNotification;
      })
    );

    this.footerContent$ = forkJoin([this.cmsContent$, of(false)]).pipe(
      map(x => [x[0], false])
    );
    this.rawHeaderCms$ = this.cmsContent$.pipe(map(x => x.header));
    this.fiveDayRiskFreeGuarantee$ = this.cmsContent$.pipe(
      map(x => x.headerDisclosure)
    );
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const pageUrl = event.url.split('/');
        const pageName = pageUrl[pageUrl.length - 1].split('?')[0];

        // fix footer menu item navigation for pages with query params.
        // Remove after update @elevate/layout to 14+
        const currentUrl = this.router.url.split('/');
        const currentPage = currentUrl[currentUrl.length - 1].split('?');
        if (
          currentPage.length > 1 &&
          currentPage[0] === pageName &&
          event.url.split('?').length === 1
        ) {
          this.router.navigate([pageName], {
            queryParams: this.route.snapshot.queryParams
          });
        }
        this.progressBarService.resetProgressBar();
      }
      if (event instanceof NavigationEnd) {
        let pageUrl = event.url.split('/');
        let pageName = pageUrl[pageUrl.length - 1].split('?')[0];
        if (!pageName) {
          pageUrl = event.urlAfterRedirects.split('/');
          pageName = pageUrl[pageUrl.length - 1].split('?')[0];
        }
        if (this.loadingService.isOpen) {
          this.loadingService.close();
        }
        this.applicationFlowService.saveContinuePath(pageName);
        this.userInactivityService.pingInterruptionToIdle();

        this.previousPageDetails = this.router
          .getCurrentNavigation()
          ?.previousNavigation?.finalUrl?.toString();
      }
      const pageComponentRoute = this.getPageComponentRoute(this.route);
      const routeData = pageComponentRoute.snapshot.data;
      this.setTitle(routeData);
      this.cobrowseConfig = this.environment
        ?.coBrowseConfiguration as CobrowseConfig;
    });
    const coBrowseEnabledFlag = this.sessionStorageService.getObject(
      SessionStorageObjects.coBrowseEnabledFlag
    );
    if (coBrowseEnabledFlag == null) {
      this.configurationService.IsCoBrowseEnabled().subscribe(data => {
        this.isCobrowseEnabled = data;
        this.sessionStorageService.setObject(
          SessionStorageObjects.coBrowseEnabledFlag,
          data
        );
      });
    } else {
      this.isCobrowseEnabled = coBrowseEnabledFlag;
    }
  }

  public async ngOnInit(): Promise<void> {
    this.showCookieNotification =
      this.environment.showCookieNotification &&
      this.getPageComponentRoute(this.route).snapshot?.data?.isLanding;
    await lastValueFrom(this.cmsService.getEntries('layout')).then(data => {
      this.cobrowseConfig.linkText = 'Co-Browse';
      if (this.isCobrowseEnabled) {
        this.cobrowse = { content: data.coBrowse, config: this.cobrowseConfig };
      }
    });
    this.progressBarService.progressBarRequired.subscribe((status:boolean) => {
      this.isProgressBarRequired = status;
    })
    this.googleanalytics.formStepEvent({
      form_destination: this.locationProxy.pathname,
      step_name: this.previousPageDetails
    });
    this.banners$ = this.bannerService.activeBanners$;
  }

  public async ngAfterViewInit(): Promise<void> {
    this.googleanalytics.pushPageLeveldata();

    if (
      this.googleanalytics.getGoogleVariableValue('logrocket_id') == null ||
      this.googleanalytics.getGoogleVariableValue('logrocket_id') == ''
    ) {
      this.googleanalytics.setGoogleTagManagerVariables({
        logrocket_id: this.cookieService.getSessionId()
      });
    }
    const modalClickAnchor = document.getElementsByTagName('a');

    const allanchorElements: Element[] = Array.from(modalClickAnchor);

    for (let item of allanchorElements) {
      if (item.getAttribute('data-ga4') === null) {
        const clickData: GtagLinkData = {
          link_id: item.id,
          link_text: item.textContent,
          link_classes: item.className,

          link_domain: item.baseURI,
          link_url:
            item.attributes['href']?.value == null
              ? this.locationProxy.href
              : item.attributes['href']?.value
        };
        clickData.link_type = 'page_cta_link';

        item.addEventListener('click', () => {
          this.googleanalytics.ctaClickEvent(clickData);
        });
      }
    }
  }

  public onNavClick($event: any, navType: string = 'footer_link'): void {
    $event.preventDefault();
    const target = $event.target;
    let url = `${this.environment.bau.url}/what-it-costs`;
    if (target && target.innerText === 'What It Costs') {
      const form = this.appDataService.getApplication();
      this.lenderCode = form?.product?.lenderCode;
      this.stateCode =
        form?.form?.applicant?.residences[0].address?.stateCode ??
        this.stateEligibilityService.stateCode ??
        null;

      if (this.stateCode && this.lenderCode) {
        url += `?state=${this.stateCode}&lender=${
          lender[`${this.lenderCode}`]
        }`;
      } else if (this.stateCode) {
        url += `?state=${this.stateCode}`;
      }
      this.window.open(url, '_blank');
    }
    const clickData: GtagLinkData = {
      link_id: $event.target.id,
      link_text: $event.target.textContent,
      link_classes: $event.target.className,
      link_domain: $event.target.baseURI,
      link_url: $event.target.attributes['href']?.value,
      link_type: navType
    };
    this.googleanalytics.navClickEvent(clickData);
  }

  public get accountBaseUrl(): string {
    return this.environment?.navigation?.accountBaseUrl;
  }
  public get publicBaseUrl(): string {
    return this.environment?.navigation?.publicBaseUrl;
  }

  public logoClicked(event: string): void {
    // if event === 'home' it means that user clicked on main logo.
    // else event may contain the menu item id that was clicked (e.g. 'signOut')
    if (event !== 'home') {
      return;
    }

    const pageComponentRoute = this.getPageComponentRoute(this.route);
    const routeData = pageComponentRoute.snapshot.data;
    const flow = routeData.flow ?? this.route.snapshot.data.flow;
    if (flow === 'application') {
      const confirmationModal = this.modalService.open(
        RestrictNavigationModalComponent,
        {
          backdrop: 'static',
          centered: true
        }
      );

      confirmationModal.result.then((modalResult: boolean) => {
        if (modalResult) {
          this.sessionStorageService.clearAll();
          this.appDataService.resetApplication();
          this.underwritingService.resetDecision();
          this.redirectToBau();
        }
      });
    } else {
      this.redirectToBau();
    }
  }

  public closeBanner(bannerName: string): void {
    this.bannerService.closeBanner(bannerName);
  }

  getNotificationStatus(isCookieBannerEnabled: boolean) {
    if (isCookieBannerEnabled) {
      this.appDataService.setCookieBannerStatusInSession(isCookieBannerEnabled);
      const data: GtagModalData = {
        modal_name: 'Consent CCPA Banner',
        link_text: null
      };
      this.googleanalytics.viewModalEvent(data);
    } else if (
      !isCookieBannerEnabled &&
      this.appDataService.getCookieBannerStatusInSession()
    ) {
      const consentPromptBannerEventData: GtagModalData = {
        action: ModalClickAction.Close,
        modal_name: 'Consent CCPA Banner',
        link_text: ModalClickAction.Close
      };
      this.googleanalytics.clickModalEvent(consentPromptBannerEventData);
      this.appDataService.setCookieBannerStatusInSession(isCookieBannerEnabled);
    }
  }

  private setTitle(routeData: Data): void {
    const title = routeData.title;
    this.titleService.setTitle(title);
  }

  private getPageComponentRoute(route: ActivatedRoute): ActivatedRoute {
    if (route.firstChild) {
      return this.getPageComponentRoute(route.firstChild);
    }
    return route;
  }

  private redirectToBau(): void {
    this.window.location.href = this.environment.bau.url;
  }

  private redirectToSignIn(): void {
    this.window.location.href = `${this.environment.bau.secureUrl}/${this.environment.navigation.login}`;
  }

  public signIn(event: string): void {
    if (event !== 'signIn') {
      return;
    }

    const pageComponentRoute = this.getPageComponentRoute(this.route);
    const routeData = pageComponentRoute.snapshot.data;
    const flow = routeData.flow ?? this.route.snapshot.data.flow;
    if (flow === 'application') {
      const confirmationModal = this.modalService.open(
        RestrictNavigationModalComponent,
        {
          backdrop: 'static',
          centered: true
        }
      );

      confirmationModal.result.then((modalResult: boolean) => {
        if (modalResult) {
          this.sessionStorageService.clearAll();
          this.appDataService.resetApplication();
          this.underwritingService.resetDecision();
          this.redirectToSignIn();
        }
      });
    } else {
      this.redirectToSignIn();
    }
  }
}
