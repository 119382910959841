import { Injectable } from '@angular/core';

export enum ABTestElasticTestSegment {
  TestName = 'ElasticAppFlowImprovements',
  SegmentName = 'InitialImprovements'
}

export enum LocalStorageObjects {
  ABTestElasticAppFlowImprovementsResult = 'AbTest_ElasticAppFlowImprovements_Result'
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  public setObject(key: LocalStorageObjects, value: any): void {
    const jsonObj = JSON.stringify(value);
    localStorage.setItem(key, jsonObj);
  }

  public getObject(key: LocalStorageObjects): any {
    const localString = localStorage.getItem(key);
    return JSON.parse(localString);
  }

  public deleteItem(key: any): void {
    localStorage.removeItem(key);
  }



  public clearAll(): void {
    localStorage.clear();
  }
}
