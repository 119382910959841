import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbTestingData, ApplicationFlow, ApplicationForm } from '@application/application';
import { Environment } from '@environment/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { delay, retryWhen, takeWhile, tap, catchError } from 'rxjs/operators';
import { StartOption } from '../../application/application.api';

export interface ReapplyStartOptionsResponse {
  startOptions?: StartOption;
  applicationId?: string;
  customerId?: string;
  customerIdSignature?: string;
  phoneNumberLast4?: string;
}

export interface ReapplyCreateAppRequest {
  brand: string;
  applicationFlow: ApplicationFlow;
  form: ApplicationForm;
  abTesting?: AbTestingData[];
}

export interface ReapplyRequest {
  brand: string;
}

export interface ReapplyCreateApplicationResponse {
  token: string;
}

@Injectable({ providedIn: 'root' })
export class ReapplyApiService {
  constructor(
    private environment: Environment,
    private httpClient: HttpClient,
    private modalService: NgbModal,
    private router: Router
  ) {}



  /* istanbul ignore next */
  public startOptions(): Observable<ReapplyStartOptionsResponse> {
    return this.httpClient
      .get<ReapplyStartOptionsResponse>(
        `${this.environment.brandApi.url}/api/v1/customer/reapply/start-options?brand=${this.environment.brand}`,
        {
          withCredentials: true
        }
      )
      .pipe(
        retryWhen((error: any) => {
          let retryCount = 0;
          return error.pipe(
            delay(this.environment.httpRetry.retryTimeout),
            takeWhile(() => retryCount < this.environment.httpRetry.retryCount),
            tap(
              () => retryCount++,
              () => {},
              () => {
                this.modalService.dismissAll();
                this.router.navigate(['/error']);
                throw new Error();
              }
            )
          );
        })
      );
  }

  /* istanbul ignore next */
  public reapplyCreate(
    request: ReapplyCreateAppRequest
  ): Observable<ReapplyCreateApplicationResponse> {
    return this.httpClient
      .post<ReapplyCreateApplicationResponse>(
        `${this.environment.brandApi.url}/api/v1/customer/reapply/create-application`,
        request,
        {
          withCredentials: true
        }
      )
      .pipe(
        catchError((error: any) => {
          this.modalService.dismissAll();
          this.router.navigate(['/error']);
          return throwError(error);
        })
      );
  }
}
