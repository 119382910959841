import { ConsentSectionItem } from '@application/consents/consents.content';
import { SelectOption } from '@elevate/ui-components';
import { EmployerCMSValidationMessages } from './sources/components/employer/employer';
import { PaymentFrequencyCMSValidationMessages } from './sources/components/payment-frequency/payment-frequency';
import {
  JobIncome,
  JobIncomeCMSValidationMessages
} from './sources/job-income/job-income';
import { Other, OtherCMSValidationMessages } from './sources/other/other';
import { Retirement } from './sources/retirement/retirement';
import {
  SelfEmploedCMSValidationMessages,
  SelfEmployed
} from './sources/self-employed/self-employed';
import { SocialSecurity } from './sources/social-security/social-security';
import { Unemployment } from './sources/unemployment/unemployment';

export enum PrimarySourceValue {
  JobIncome = 'JobIncome',
  SelfEmployed = 'SelfEmployed',
  SocialSecurityDisability = 'SocialSecurityDisability',
  RetirementPension = 'RetirementPension',
  Other = 'Other',
  Unemployment = 'Unemployment'
}

export enum PrimarySourceDisplayName {
  JobIncome = 'Job income',
  SelfEmployed = 'Self-employed',
  SocialSecurityDisability = 'Social Security / Disability',
  RetirementPension = 'Pension / Retirement',
  Other = 'Other',
  Unemployment = 'Unemployment'
}

export enum SecondarySourceValue {
  JobIncome = 'JobIncome',
  SelfEmployed = 'SelfEmployed',
  SocialSecurityDisability = 'SocialSecurityDisability',
  RetirementPension = 'RetirementPension',
  RentalProperty ='RentalProperty',
  AlimonyChildSupport= 'AlimonyChildSupport',
  Unemployment='Unemployment',
  Other = 'Other'
}

export enum SecondarySourceDisplayName {
  JobIncome = 'Job income',
  SelfEmployed = 'Self-employed',
  SocialSecurityDisability = 'Social Security / Disability',
  RetirementPension = 'Pension / Retirement',
  RentalProperty ='Rental Property',
  AlimonyChildSupport= 'Alimony / Child support / Separate maintenance', 
  Unemployment = 'Unemployment',
  Other = 'Other'
}

export interface Income
  extends JobIncome,
    SelfEmployed,
    SocialSecurity,
    Retirement,
    Other,
    Unemployment {
  primarySource: PrimarySourceValue;
  secondarySource?: SecondarySourceValue;
  secondaryNetAmount?: string;
  monthlyRentMortgage: string;
}

export interface IncomeSourceComponent {
  value: object;
  patchValue: (value: object) => void;
  showValidationErrors: () => void;
}

export interface IncomeCMSData {
  header: string;
  subheader: string;
  disclosure: string;
  incomeSourceInfo: string;
  mortgageRentLabel: string;
  employer: {
    label: string;
    secondIncomeSource: string;
    isenabled: boolean;
    validation: EmployerCMSValidationMessages;
  };
  jobIncome: {
    workExtensionLabel: string;
    workPhoneLabel: string;
    isphoneenabled: boolean;
    validation: JobIncomeCMSValidationMessages;
  };
  other: {
    contactExtensionLabel: string;
    contactPhoneLabel: string;
    isphoneenabled: boolean;
    validation: OtherCMSValidationMessages;
  };
  pay: {
    nextPayDateLabel: string;
    semiMonthLabel: string;
    monthlyLabel: string;
    paymentFrequencyLabel: string;
    ispaydateenabled: boolean;
    paymentFrequencyOptions: SelectOption[];
    takeHomeAmountLabel: string;
    takeHomeInfo: string;
    validation: PaymentFrequencyCMSValidationMessages;
  };
  sources: {
    mainIncome: { incomeType: SelectOption[]; label: string };
    secondaryIncome: {
      incomeType: SelectOption[];
      label: string;
      netAmountLabel: string;
    };
  };
  selfEmployed: {
    netAmountLabel: string;
    helpText: string;
    validation: SelfEmploedCMSValidationMessages;
  };
  validation: IncomeCMSValidationMessages;
  buttons: {
    continue: string;
  };
  consentsSection?: ConsentSectionItem[];
}

export interface IncomeCMSValidationMessages {
  sourceMsg: string;
  incomeAmountMsg: string;
  homeMsg: string;
  homeRangeMsg: string;
  rangeMsg: string;
}

export const IncomeCMSDataConst = {
  buttons: {
    continue: 'CONTINUE'
  },
  disclosure:
    'Alimony, child support, or separate maintenance income need not be disclosed if you do not wish it to be considered as a basis for repaying this obligation.',
  employer: {
    label: 'Company/Employer',
    isenabled: true,
    validation: {
      companyRules: 'Alphabetical or numerical characters only please.',
      nameMsg: 'Please enter company/employer name.'
    }
  },
  header: 'Please enter some additional details about yourself',
  incomeSourceInfo:
    'Do not include the income of a spouse or another person in your total income calculation. To qualify, you don’t have to be employed, but you must have a regular source of income.',
  jobIncome: {
    isphoneenabled: true,
    workExtensionLabel: 'Best phone number to reach you at work',
    workPhoneLabel: 'Extension (Optional)',
    validation: {
      lengthMsg: 'Please enter full 10 digit phone number.',
      phoneMsg: 'Please enter the best number to reach you at work.'
    }
  },
  mortgageRentLabel: 'Monthly rent or mortgage',
  other: {
    isphoneenabled: true,
    contactPhoneLabel: 'What number can we call to verify income?',
    contactExtensionLabel: 'Extension (Optional)',
    validation: {
      phoneLengthRule: 'Please enter full 10 digit phone number.',
      verifyPhone: 'Please enter the number we can call to verify income.'
    }
  },
  pay: {
    ispaydateenabled: true,
    nextPayDateLabel: 'What day is your next pay date?',
    semiMonthLabel: 'Which two days are you paid each month?',
    monthlyLabel: 'Which day are you paid each month?',
    paymentFrequencyLabel: 'How often are you paid?',
    paymentFrequencyOptions: [
      {
        value: 'Weekly',
        label: 'Weekly'
      },
      {
        value: 'BiWeekly',
        label: 'Bi-weekly'
      },
      {
        value: 'SemiMonthly',
        label: 'Semi-Monthly'
      },
      {
        value: 'Monthly',
        label: 'Monthly'
      }
    ],
    takeHomeAmountLabel: 'Take-home amount of your last paycheck',
    takeHomeInfo:
      'Take-home amount is the same as net income. Net income is your income after taxes and deductions. For example: a person earns wages of $1,000 and $300 in deductions are taken from their paycheck. Their gross income is $1,000 and their net income is $700.',
    validation: {
      amountRange: 'Please enter an amount between $1 and $9,999.',
      howOften: 'Please select how often you are paid.',
      nextPayDate: 'Please select the date of your next payday.',
      paidMonthly: 'Please select the two days you get paid every month.',
      takeAmount: 'Please enter the take-home amount of your last paycheck.',
      whichDay: 'Please select the day you get paid every month.'
    }
  },
  selfEmployed: {
    netAmountLabel: 'Monthly net income amount',
    helpText:
      'Net Income is your income after taxes and deductions. For example, a person earns wages of $1,000 and $300 in deductions are taken from their paycheck. Their gross income is $1,000 and their net income is $700.',
    validation: {
      netamountMsg: 'Please enter your Monthly net income amount.',
      rangeRule: 'Please enter an amount between $1 and $9,999.'
    }
  },
  sources: {
    mainIncome: {
      incomeType: [
        {
          label: 'Job income',
          value: 'JobIncome'
        },
        {
          label: 'Self-employed',
          value: 'SelfEmployed'
        },
        {
          label: 'Social Security / Disability',
          value: 'SocialSecurityDisability'
        },
        {
          label: 'Pension / Retirement',
          value: 'RetirementPension'
        },
        {
          label: 'Other',
          value: 'Other'
        }
      ],
      label: 'Main Source of Income'
    },
    secondaryIncome: {
      incomeType: [
        {
          value: 'JobIncome',
          label: 'Job income'
        },
        {
          value: 'SelfEmployed',
          label: 'Self-employed'
        },
        {
          value: 'SocialSecurityDisability',
          label: 'Social Security / Disability'
        },
        {
          value: 'RetirementPension',
          label: 'Pension / Retirement'
        },
        {
          value: 'Other',
          label: 'Other'
        }
      ],
      label: 'Second Source of Income',
      netAmountLabel: 'Monthly net amount'
    }
  },
  subheader:
    'We need your income information to verify Swell is right for you!',
  validation: {
    homeMsg: 'Please enter your Monthly rent or mortgage amount.',
    incomeAmountMsg: 'Please enter your Monthly income amount.',
    rangeMsg: 'Please enter an amount between $1 and $9,999.',
    sourceMsg: 'Please select a source of income.'
  }
} as IncomeCMSData;
