import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationDataService } from '@application/application.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import {
  ConfigurationService,
  DecisionFlowResponse,
  PlatformConfigFeatures
} from '@core/configuration/configuration.service';
import { DocumentApi } from '@core/document/document.api';
import { DocumentService } from '@core/document/document.service';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import {
  DocumentUploadComponent,
  DocumentUploadConfig,
  DUCmsContent
} from '@elevate/document-upload';
import { Environment } from '@environment/environment';
import moment from 'moment';
import 'moment-timezone';
import { lastValueFrom } from 'rxjs';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { LocationProxy } from '@core/location-proxy/location-proxy.service';

export interface UploadDocumentPageCMS {
  header: string;
  subHeader: string;
  alternateRfaiSubheader?: string;
  content: string;
  documents: UploadDocumentInfo[];
  documentUpload: DUCmsContent;
}

export interface UploadDocumentInfo {
  document: string;
  description: string;
  documentId: string;
}

@Component({
  selector: 'app-document-upload-page',
  templateUrl: './document-upload-page.component.html',
  styleUrls: ['./document-upload-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentUploadPageComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('documentUploadTextSection', { static: false })
  private documentUploadText: ElementRef;
  @ViewChild('documentUploadComponent')
  public documentUploadComponent: DocumentUploadComponent;

  private readonly rfaiDocumentDownloadLinkId = 'rfaiDocumentDownloadLink';
  private readonly documentName = `rfai_${Date.now()}.pdf`;
  public rfaiCode: string;
  public cmsMappedDocuments: UploadDocumentInfo[] = [];
  public documentUploadConfig: DocumentUploadConfig;

  public pageContent: UploadDocumentPageCMS;
  public contentText: string;
  public documentTypesResult: string[] = [];
  public selectedDocuments: string[] = [];
  public previousSelectedDocuments: string[] = [];
  public application: any;
  private allButtonElements: Element[] = [];
  public subHeader: string;

  constructor(
    private applicationDataService: ApplicationDataService,
    private cmsPageContentService: CmsPageContentService,
    private documentApi: DocumentApi,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private environment: Environment,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private configurationService: ConfigurationService,
    private appInsightsService: AppInsightsService,
    private googleAnalytics: GoogleAnalytics,
    private locationProxy: LocationProxy
  ) {
    this.application = this.applicationDataService.getApplication();
    this.rfaiCode = this.application?.trackedRequests?.find(
      x => (x.status = 'Incomplete')
    )?.name;
  }

  public async ngOnInit(): Promise<void> {
    this.pageContent = this.route.snapshot.data.cmsContent.documentUpload;

    if (this.rfaiCode == '67') {
      this.subHeader = this.pageContent.alternateRfaiSubheader;
    } else {
      this.subHeader = this.pageContent.subHeader;
    }

    const applcantIdentity = this.application.form?.applicant?.identity;

    this.cmsPageContentService.updatePageTitle(
      this.getPageTitle(applcantIdentity.firstName, applcantIdentity.lastName)
    );

    this.contentText = this.getPageContentText(this.application.expiresAt);

    let rfaiDocumentConfig: DecisionFlowResponse;

    try {
      rfaiDocumentConfig = await lastValueFrom(
        this.configurationService.getConfigFeature<DecisionFlowResponse>(
          PlatformConfigFeatures.DecisionFlow
        )
      );
    } catch (error) {
      this.appInsightsService.trackException(error);
      this.router.navigate(['/error']);
      return;
    }

    const requiredDocuments = rfaiDocumentConfig?.documentUploadRFAICodes?.find(
      rfaicodesconfig => rfaicodesconfig.code === this.rfaiCode
    );

    if (!requiredDocuments) {
      this.appInsightsService.trackException(
        new Error(
          `Missing PlatformConfig configuration for RFAI code ${this.rfaiCode} in current brand`
        )
      );
      this.router.navigate(['/error']);
      return;
    }

    requiredDocuments.requestedDocuments.forEach((documentId: string) => {
      const cmsData = this.pageContent.documents.find(
        cmsDocumentData => cmsDocumentData.documentId === documentId
      );

      if (!cmsData) {
        this.appInsightsService.trackException(
          new Error(
            `RFAI code "${documentId}" from pfc is not defined in CMS data, make sure code is defined in CMS and contains a valid id`
          )
        );

        this.router.navigate(['/error']);
        return;
      }

      this.cmsMappedDocuments.push(cmsData);
    });

    this.documentTypesResult = this.cmsMappedDocuments.map(
      (document: UploadDocumentInfo) => document.document
    );

    this.setDocumentUploadComponentConfig();
  }

  public ngAfterViewInit(): void {
    this.documentUploadText?.nativeElement
      .querySelector(`#${this.rfaiDocumentDownloadLinkId}`)
      .addEventListener('click', () => {
        this.download();
      });
    document.querySelector('#fileDropRef').addEventListener('click', event => {
      this.raiseGA4CtaClickEvent(event);
    });
    const allbuttons = document.getElementsByTagName('button');

    this.allButtonElements = Array.from(allbuttons);

    for (let item of this.allButtonElements) {
      item.addEventListener('click', event => {
        this.raiseGA4CtaClickEvent(event);
      });
    }
  }

  public ngOnDestroy(): void {
    this.documentUploadText?.nativeElement
      .querySelector(`#${this.rfaiDocumentDownloadLinkId}`)
      .removeEventListener('click', this.download);

    document.querySelector('#fileDropRef').removeAllListeners();

    for (let item of this.allButtonElements) {
      item.removeAllListeners();
    }
  }

  public get disableSubmit(): boolean {
    return this.cmsMappedDocuments.some(
      d => !this.selectedDocuments.some(sd => sd === d.document)
    );
  }

  public get currentUrl(): string {
    return this.router.url;
  }

  public isDocumentSelected(document: string): boolean {
    return this.selectedDocuments.some(d => d === document);
  }

  public download = async (): Promise<void> => {
    const blob: Blob = await lastValueFrom(this.documentApi.get('rfai'));
    this.documentService.download(blob, this.documentName);
  };

  public documentTypeChanged(event: string[]): void {
    //Find our what document type has changed, it always provides the whole list of documents on teh page.
    for (var index = 0; index <= event.length; index++) {
      if (this.previousSelectedDocuments.length == 0) {
        if (event[index] != undefined) {
          this.googleAnalytics.formInteractionEvent({
            field_id: 'documentType' + (index + 1),
            form_submit_text: event[index]
          });
        }
      } else {
        if (
          event[index] != this.previousSelectedDocuments[index] &&
          event[index] != undefined
        ) {
          this.googleAnalytics.formInteractionEvent({
            field_id: 'documentType' + (index + 1),
            form_submit_text: event[index]
          });
          break;
        }
      }
    }

    this.previousSelectedDocuments = event;
    this.selectedDocuments = event?.filter(d => !!d) ?? [];
  }

  public raiseModalOpen(event: any): void {
    this.googleAnalytics.viewModalEvent({
      modal_name: event?.modalHeading,
      link_text:
        event?.modalHeading.toString() == 'Please confirm'
          ? 'SUBMIT REQUEST'
          : 'RESET'
    });
  }
  public raiseModalClose(event: any): void {
    this.googleAnalytics.viewModalEvent({
      modal_name: event?.modalHeading
    });
  }
  public raiseModalClick(event: any): void {
    this.googleAnalytics.clickModalEvent({
      modal_name: event?.modalHeading,
      action: 'Click',
      link_text: event?.buttonName
    });
  }

  public cancelUploadDocuments(): void {
    this.documentUploadComponent.deleteAllDocuments();
  }

  public raiseGA4CtaClickEvent(event: any) {
    this.googleAnalytics.ctaClickEvent({
      link_type: 'button',
      link_id: event.target.id,
      link_text:
        event.target.id == 'fileDropRef'
          ? 'Add Documents'
          : event.target.textContent,
      link_classes: event.target.className,
      link_domain: event.target.baseURI,
      link_url:
        event.target.attributes['href']?.value == null
          ? this.locationProxy.href
          : event.target.attributes['href']?.value
    });
  }

  public submit(): void {
    this.router.navigate(['/processing-docs']);
  }

  public deleteDocument(event: string): void {
    if (event) {
      const index = this.selectedDocuments.indexOf(event);
      this.selectedDocuments.splice(index, 1);
    }
  }

  private getPageTitle(firstName: string, lastName: string): string {
    const res = this.pageContent.header.replace('{firstName}', firstName);
    return res.replace('{lastName}', lastName);
  }

  private setDocumentUploadComponentConfig(): void {
    const productId = this.application.product.id;
    const envConfig = this.environment.duConfig;
    const authToken = this.sessionStorageService.getItem(
      SessionStorageKeys.authorizationToken
    );

    this.documentUploadConfig = {
      uploadUrl: `${envConfig.uploadUrl}?brandId=${this.environment.brandId}&productId=${productId}`,
      baseUrl: envConfig.baseUrl,
      acceptedFileTypes: envConfig.acceptedFileTypes,
      maxFileSize: envConfig.maxFileSizeMb,
      maxLimit: envConfig.maxFileCount,
      requireLimit: envConfig.minFileCount,
      shouldSaveDocument: envConfig.shouldSaveDocument,
      shouldDisplayErrorMessage: envConfig.shouldDisplayErrorMessage,
      uploadType: envConfig.uploadType,
      additionalHeaders: envConfig.headers,
      productToken: `Bearer ${authToken}`,
      documentTypes: this.documentTypesResult
    } as DocumentUploadConfig;
  }

  private getPageContentText(expiresAt: string): string {
    const applicationExpirationDate = moment
      .utc(expiresAt)
      .tz('America/Chicago')
      .format('MM/DD/YY');

    return this.pageContent.content.replace(
      '{applicationExpirationDate}',
      applicationExpirationDate
    );
  }

  public debugHudUploadDocuments(): void {
    this.debugHudUploadFiles(this.cmsMappedDocuments.length);
    setTimeout(async () => {
      const inpuTypeElements = document.querySelectorAll(
        'ecl-select.ng-invalid input'
      ) as any;
      for (let i = 0; i < this.cmsMappedDocuments.length; i++) {
        inpuTypeElements[i]?.click();
        await new Promise(resolve => setTimeout(resolve, 10));
        const element = document.querySelector(
          `ecl-select.ng-invalid ul :nth-child(${i + 1})`
        ) as any;
        element.click();
      }
    });
  }

  private debugHudUploadFiles(count: number): void {
    const dataTransfer = new DataTransfer();
    for (let i = 0; i < count; i++) {
      dataTransfer.items.add(new File([''], 'test-file.pdf'));
    }
    const inputDebugEl = document.querySelector('#fileDropRef') as any;

    inputDebugEl.files = dataTransfer.files;

    inputDebugEl.dispatchEvent(new InputEvent('change'));
  }
}
