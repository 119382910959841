import { Injectable } from '@angular/core';
import { CookieEnums, CookieService } from '@core/cookie/cookie-service';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { Environment } from '@environment/environment';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class TemporaLockService {
  constructor(
    private environment: Environment,
    private sessionStorageService: SessionStorageService,
    private cookieService: CookieService
  ) {}

  public checkLockStatus(): boolean {
    const temporalLockCookie =
      this.cookieService.get(CookieEnums.TemporaryLockCookie) === 'true';

    if (temporalLockCookie) {
      return true;
    }

    return false;
  }

  public increaseCount(): void {
    const sessionVariablelockAttempts = this.sessionStorageService.getItem(
      SessionStorageKeys.temporaryLock
    );

    const lockAttemptsCountToInt = +sessionVariablelockAttempts || 0; 
    const increasedLockedAttempts = lockAttemptsCountToInt + 1;
    this.sessionStorageService.setItem(
      SessionStorageKeys.temporaryLock,
      increasedLockedAttempts
    );

    if (increasedLockedAttempts >= this.environment.tempLockThreshold.attempts) {
      //reset session variable
      this.sessionStorageService.setItem(SessionStorageKeys.temporaryLock, 0);

      //set cookie
      this.cookieService.set(CookieEnums.TemporaryLockCookie, 'true', {
        expires: moment()
          .add(this.environment.tempLockThreshold.timerInMinutes, 'minutes')
          .toDate()
      });
    }
  }
}
