import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApplicationApi,
  ReplaceApplicationRequest
} from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { ApplicationData, FundMethodType } from '@application/application';
import { Subscription, lastValueFrom } from 'rxjs';

import { IncomeDebugService } from './debug-hud/debug-hud.service';
import {
  Income,
  IncomeCMSData,
  PrimarySourceValue,
  SecondarySourceValue
} from './income';
import { IncomeFormGroup } from './income.form';
import { IncomeFormConfig } from './income.form.config';
import { IncomeService } from './income.service';
import { JobIncomeComponent } from './sources/job-income/job-income.component';
import { OtherComponent } from './sources/other/other.component';
import { RetirementComponent } from './sources/retirement/retirement.component';
import { SelfEmployedComponent } from './sources/self-employed/self-employed.component';
import { SocialSecurityComponent } from './sources/social-security/social-security.component';
import { UnemploymentComponent } from './sources/unemployment/unemployment.component';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ConsentSectionItem } from '@application/consents/consents.content';
import { ConsentsComponent } from '@application/consents/consents.component';
import { PaymentFrequencyFormConfig } from './sources/components/payment-frequency/payment-frequency.form.config';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { Environment } from '@environment/environment';
import { ProgressBarService } from '@core/progress-bar/progress-bar.service';
import { ProgressbarName } from '@core/progress-bar/progress-bar.constants';
import { EnvironmentService } from '@environment/environment.service';
import { SessionStorageKeys } from '@core/session-storage/session-storage.service';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss'],
  providers: [IncomeDebugService, IncomeService]
})
export class IncomeComponent implements OnInit, OnDestroy {
  public income: IncomeCMSData;
  public pageSubTitle: string;
  public alimonyInfo: string;
  public incomeSourceInfo: string;
  public form: IncomeFormGroup;
  public formConfig: IncomeFormConfig;
  public paymentFrequencyFormConfig: PaymentFrequencyFormConfig;
  public consentsSection: ConsentSectionItem[];

  @ViewChild(JobIncomeComponent)
  private jobIncome: JobIncomeComponent;

  @ViewChild(SelfEmployedComponent)
  private selfEmployed: SelfEmployedComponent;

  @ViewChild(SocialSecurityComponent)
  private socialSecurity: SocialSecurityComponent;

  @ViewChild(RetirementComponent)
  private retirement: RetirementComponent;

  @ViewChild(OtherComponent)
  private other: OtherComponent;

  @ViewChild(UnemploymentComponent)
  private unemployment: UnemploymentComponent;

  @ViewChild('consentsComponent')
  public consentsComponent: ConsentsComponent;

  private secondaryIncomeSubscription: Subscription;
  private isEditMode: boolean;
  public isABTestControl: boolean;

  constructor(
    public debugHud: IncomeDebugService,
    private incomeService: IncomeService,
    private route: ActivatedRoute,
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private loadingService: LoadingModalService,
    private cmsPageContentService: CmsPageContentService,
    private environment: Environment,
    private applicationFlowService: ApplicationFlowService,
    private progressBarService: ProgressBarService,
    private environmentService: EnvironmentService
  ) {}

  public ngOnInit(): void {
    this.income = this.route.snapshot.data.cmsContent.dIncome as IncomeCMSData;
    this.cmsPageContentService.updatePageTitle(this.income.header);
    this.form = new IncomeFormGroup(this.income.validation);
    this.formConfig = new IncomeFormConfig(this.form, this.income.sources);
    this.pageSubTitle = this.income.subheader;
    this.alimonyInfo = this.income.disclosure;
    this.incomeSourceInfo = this.income.incomeSourceInfo;
    this.consentsSection = this.income?.consentsSection;

    const application = this.applicationDataService.getApplication();
    this.isABTestControl = sessionStorage.getItem(SessionStorageKeys.isElasticImprovementFlow) == null ? true 
    :  !(sessionStorage.getItem(SessionStorageKeys.isElasticImprovementFlow) == 'true');
    
    if (!this.isABTestControl) {   
      this.progressBarService.updateProgressBarStatus(ProgressbarName.IncomeInformation);   
      this.form.controls["monthlyRentMortgage"].setValue(0);
    }

    if (application.form.applicant.income) {
      const income = this.incomeService.convertToIncome(application);
      this.isEditMode = true;

      setTimeout(() => {
        this.patchValue(income);
        this.form.updateValueAndValidity(); // NOTE: There'sdelay on initializing the select box options.
      }, 200);
    }

    this.secondaryIncomeSubscription = this.form
      .get('secondarySource')
      .valueChanges.subscribe((secondarySourceType: SecondarySourceValue) => {
        if (secondarySourceType) {
          this.form.get('secondaryNetAmount').enable();
        } else {
          this.form.get('secondaryNetAmount').disable();
          this.form.get('secondaryNetAmount').reset();
        }
      });
  }

  /* istanbul ignore next */
  public simulateData(source: string): void {
    this.consentsComponent.debugHudSubmitAllConsents();
    const income = this.debugHud.simulateData(PrimarySourceValue[source]);
    this.patchValue(income);
  }

  public async submit(): Promise<void> {
    this.showValidationErrors();

    if (!this.form.valid) {
      return;
    }
    this.loadingService.open();

    const value = this.getValue();
   
    let fundingSelection: FundMethodType = null;
    let paymentSelection: FundMethodType = null;
    if (
      !this.environment.applicationFlowSettings.isFundingPaymentSelectionEnabled
    ) {
      fundingSelection = this.environment.defaultFundingType;
    }
    if (
      !this.environment.applicationFlowSettings.isPaymentMethodSelectionEnabled
    ) {
      paymentSelection = this.environment.defaultPaymentType;
    }

    const apiModel = this.incomeService.convertToApplicationData(
      value,
      this.income.pay.ispaydateenabled,
      fundingSelection,
      paymentSelection
    );

    if (this.isEditMode) {
      this.replaceApplicationData(apiModel);
      const replaceApiModel = this.getReplaceApplicationRequest(apiModel);
      await lastValueFrom(this.applicationApi.replace(replaceApiModel));
    }
    this.applicationDataService.merge(apiModel);
    // eslint-disable-next-line no-console
    apiModel.form.disclosures !== undefined
      ? Array.prototype.push.apply(
          apiModel.form.disclosures,
          this.consentsComponent?.disclosures
        )
      : (apiModel.form.disclosures = this.consentsComponent?.disclosures);
    await lastValueFrom(this.applicationApi.append(apiModel.form));

    const nextPage = this.applicationFlowService.getContinuePath();
    this.router.navigate([nextPage]);
  }

  private showValidationErrors(): void {
    this.form.showValidationErrors();
    this.jobIncome?.showValidationErrors();
    this.selfEmployed?.showValidationErrors();
    this.socialSecurity?.showValidationErrors();
    this.retirement?.showValidationErrors();
    this.other?.showValidationErrors();
    this.unemployment?.showValidationErrors();
  }

  private getValue(): Income {
    return {
      ...this.form.value,
      ...this.jobIncome?.value,
      ...this.selfEmployed?.value,
      ...this.socialSecurity?.value,
      ...this.retirement?.value,
      ...this.other?.value,
      ...this.unemployment?.value
    };
  }

  private replaceApplicationData(application: ApplicationData): void {
    const current = this.applicationDataService.getApplication();

    const residence = current.form.applicant.residences.find(
      r => r.key === '1'
    );
    residence.monthlyAmount =
      application.form.applicant.residences[0].monthlyAmount;
    current.form.applicant.income.sources =
      application.form.applicant.income.sources;

    current.form.applicant.employments = application.form.applicant.employments;
  }

  private getReplaceApplicationRequest(
    application: ApplicationData
  ): ReplaceApplicationRequest {
    const current = this.applicationDataService.getApplication();
    const sections = {
      sections: [
        {
          path: 'applicant.residences',
          value: current.form.applicant.residences
        },
        {
          path: 'applicant.income.sources',
          value: application.form.applicant.income.sources
        }
      ]
    };
    if (this.income.employer.isenabled) {
      sections.sections.push({
        path: 'applicant.employments',
        value: application.form.applicant.employments
      });
    }
    return sections;
  }

  private patchValue(income: Income): void {
    this.form.patchValue(income);

    setTimeout(() => {
      this.jobIncome?.patchValue(income);
      this.selfEmployed?.patchValue(income);
      this.socialSecurity?.patchValue(income);
      this.retirement?.patchValue(income);
      this.other?.patchValue(income);
      this.unemployment?.patchValue(income);
      this.form.updateValueAndValidity();
    });
  }

  public getEntry(): void {}
  public ngOnDestroy(): void {
    this.secondaryIncomeSubscription.unsubscribe();
  }
}
