
export const noPoBoxValidatorName = 'pobox';

const noPoBoxRegex = [
  '((^\\b(?=(?!(?:(?:[Pp][.\\s]*[Oo][.\\s]*)\\b(?:[Bb][Oo][Xx])?)',
  '|(?:.*[Pp][Oo][Ss][Tt][Aa][Ll][Bb][Oo][Xx].*)',
  '|(?:.*[Pp][Oo]s*[Bb][Oo][Xx]\\s*\\d*)',
  '|(?:\\d*\\s*[Pp]\\s*[Oo]\\s*[Bb])|(?:[Pp][Oo][Ss][Tt]\\s+[Oo][Ff]{2}',
  '[Ii][Cc][Ee]|[Pp][Oo][Ss][Tt][Aa][Ll]\\s+[Bb][Oo][Xx]',
  '|(?:[Pp][Oo])?[Bb][Oo][Xx])\\b|(?:.*[!@$%^&*()[]{}_+=]))).*',
  '|(^\\b(?=(?:.*([Po][Oo]|[Bb][Oo][Xx])\\s+([Ss][Tt]|[Aa][Vv][Ee]',
  '|[Rr][Oo][Aa][Dd]|[Rr][Dd]|[Ll][Aa][Nn][Ee]|[Ll][Nn]))\\b))).*(^',
  "(?=(?:[/a-zA-Z0-9,.;'\\-#\\s]+$)))).*$"
];

export function checkPoBoxValidator(value: string): boolean {
  if(value){
    return (
      containsPostBox(value) ||
      containsPostBoxFollowedByNumber(value) ||
      matchRegEx(value)
    );
  }
  else{
    false;
  }
}

function matchRegEx(value: string): boolean {
  return value?.match(/noPoBoxRegex.join('')/gi) !== null;
}

export function containsPostBox(value: string): boolean {
  return [
    'POST BOX',
    'POST OFFICE',
    'POST OFFICEBOX',
    'POST OFFICEBX',
    'POSTOFFICEBOX',
    'POSTOFFICEBX',
    'POSTAL BOX',
    'PO BOX',
    'PO  Box',
    'PO B o x',
    'P.O. BOX',
    'po.box',
    'P O BOX',
    'P. O. BOX',
    'p.o.box',
    'p.o box',
    'P O B o x',
    'P O Bo x',
    'p. o box',
    'P. O.BOX',
    'PO. BOX',
    'PO.box',
    'po. box',
    'POBOX',
    'POSTOFFICE',
    'POSTALBOX',
    'POSTALBX',
    'POBX',
    'POSTBOX',
    'POSTBX',
    'PBOX',
    'PBX'
  ].some(
    (postBox: string): boolean =>
      value?.match(new RegExp(`${postBox}`, 'i')) !== null
  );
}

export function containsPostBoxFollowedByNumber(value: string): boolean {
  return ['PO', 'BOX', 'BX'].some(
    (postBox: string): boolean =>
      value?.match(new RegExp(`^${postBox}\\s*\\d+`, 'i')) !== null
  );
}
