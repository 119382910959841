import { Injectable, NgZone } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { ApplicationContinuePathGuard } from '@application/continue-path/continue-path.guard';
import {
  ApplicationState,
  CustomerApiService,
  CustomerStatus
} from '@core/customer/customer-api.service';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubmittedAppGuardService {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private customerApi: CustomerApiService,
    private appguard: ApplicationContinuePathGuard,
    private sessionStorageService: SessionStorageService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const authToken = this.sessionStorageService.getItem(
      SessionStorageKeys.authorizationToken
    );
    if (this.appguard.isAuthenticated && !authToken) {
      return this.customerApi.getCustomerStatus().pipe(
        map((response: CustomerStatus) => {
          const appState = ApplicationState[response.applicationState];

          if (this.isApplicationSubmitted(appState)) {
            this.ngZone.run(() => {
              this.router.navigate(['pending']);
            });

            return false;
          }
          return true;
        }),
        catchError((error: any) => {
          return of(true);
        })
      );
    }
    return true;
  }

  public isApplicationSubmitted(appState: string): Boolean {
    return [
      ApplicationState.Approved,
      ApplicationState.Declined,
      ApplicationState.PendingDecision
    ].some((status: ApplicationState) => status === appState);
  }
}
