<ng-container *ngIf="applicationProgress">
  <div class="app-progress-bar">
    <ng-container
      *ngFor="let progressItem of applicationProgress; let i = index"
    >
      <div
        class="progress-item"
        [ngStyle]="{ width: 100 / applicationProgress.length + '%' }"
      >
        <div
          class="progress-line-circle"
          [ngClass]="
            progressItem.status === 'completed'
              ? 'completed'
              : progressItem.status === 'inprogress'
              ? 'inprogress'
              : 'not-started'
          "
        >
          <div class="circle">
            <i
              *ngIf="progressItem.status === 'completed'"
              class="fas fa-check-circle ng-star-inserted"
              aria-hidden="true"
            ></i>
          </div>
          <div
            *ngIf="i < applicationProgress.length - 1"
            class="break-line"
          ></div>
        </div>
        <div class="progress-item-name">
          <p id="trackerName">{{ progressItem.name }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
