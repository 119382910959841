import { Injectable, afterNextRender } from '@angular/core';
import { AbTestingData } from '@application/application';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { LogRocketService } from '@core/logrocket/logrocket.service';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { EnvironmentService } from '@environment/environment.service';

export interface IAbTestResult {
  testName: string;
  segmentName: string;
}

@Injectable({ providedIn: 'root' })
export class ABTestForAppFlowResolver {
  public abTestingDataArr: AbTestingData[] = [];
  constructor(
    private logrocketService: LogRocketService,
    private appInsightsService: AppInsightsService,
    public googleAnalytics: GoogleAnalytics,
    public environmentService: EnvironmentService,
    public sessionStorageService: SessionStorageService
  ) {
    if (this.environmentService?.elasticTestSegment) {
      this.setABTestResultToDataLayer(
        this.environmentService.elasticTestSegment
      );
    }
  }

  resolve(): void {}

  public setABTestResultToDataLayer(abTestingData: AbTestingData) {
    let dataSet = this.sessionStorageService.getItem(
      SessionStorageKeys.dataLayerSet
    );
    if (dataSet === null) {
      this.appInsightsService.trackTrace(
        { message: abTestingData.testName },
        abTestingData
      );

      this.logrocketService.addAbTestingData(
        abTestingData.testName,
        abTestingData.segmentName
      );

      this.abTestingDataArr = [abTestingData];

      this.googleAnalytics.setGoogleTagManagerVariables({
        abTesting: [abTestingData]
      });
      this.sessionStorageService.setItem(SessionStorageKeys.dataLayerSet, true);
    }
  }
}
