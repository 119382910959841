<div class="upload-document-page-container">
  <h2 class="page-section-title" id="uploadDocumentSubTitleText">
    {{ subHeader }}
  </h2>

  <div
    class="upload-document-page-content"
    #documentUploadTextSection
    [innerHTML]="contentText | safe: 'html'"
  ></div>
  <div class="upload-document-page-documents">
    <div
      *ngFor="let document of cmsMappedDocuments"
      class="upload-document-page-document"
    >
      <i
        *ngIf="!isDocumentSelected(document.document)"
        class="fa fa-info-circle upload-document-page-document-icon"
      ></i>
      <i
        *ngIf="isDocumentSelected(document.document)"
        class="fa fa-check-circle upload-document-page-document-icon selected"
      ></i>
      <span [innerHTML]="document.description | safe: 'html'"></span>
    </div>
  </div>
  <ecl-document-upload
    #documentUploadComponent
    [config]="documentUploadConfig"
    [cmsContent]="pageContent.documentUpload"
    [disableSubmitButton]="disableSubmit"
    [privacyPolicyUrl]="currentUrl"
    (documentTypeChange)="documentTypeChanged($event)"
    (onCancel)="cancelUploadDocuments()"
    (submitDocuments)="submit()"
    (delete)="deleteDocument($event)"
    (modalConfirm)="raiseModalClick($event)"
    (modalOpen)="raiseModalOpen($event)"
    (modalClose)="raiseModalClose($event)"
    (modalCancel)="raiseModalClick($event)"
  >
  </ecl-document-upload>
</div>

<app-debug-hud>
  <button (click)="debugHudUploadDocuments()">upload documents</button>
</app-debug-hud>
