<ng-container>
  <div class="sub-header" [innerHTML]="content.subHeader"></div>
  <form *ngIf="!emailVerified" [formGroup]="form" (submit)="onSubmit()">
    <div class="married-content" *ngIf="isWisconsin">
      <p id="maritalHeader" class="ecl-font-body-bold">
        {{ content.maritalStatus.header | uppercase }}
      </p>
      <p id="maritalContent">
        {{ content.maritalStatus.content }}
      </p>
      <div class="email-input">
        <ecl-select
          [formControlConfig]="maritalStatusConfig"
          formControlName="maritalStatus"
          (change)="showAdditionalQuestions($event)"
        >
          {{ content.maritalStatus.maritalStatusSelect.label }}
        </ecl-select>
      </div>
      <app-married *ngIf="hideAdditionalQuestions" [formGroup]="form">
      </app-married>
    </div>
    <div class="email-input">
      <ecl-text-input
        id="confirmationCodeInput"
        [formControlConfig]="emailCodeConfig"
        formControlName="confirmationCode"
        googleAnalyticsFocusOut="activateConfirmationCode"
      >
        {{ content.labels.activationCode }}
      </ecl-text-input>
    </div>
    <div class="ecl-button-hgroup ecl-wrap-reverse continue-button-container">
      <button
        (click)="resendConfirmationCode()"
        type="button"
        id="approvalResendButton"
        class="ecl-button-secondary"
        googleAnalyticsClick="ResendConfirmationCode"
      >
        {{ content.buttons.resendCode | uppercase }}
      </button>
      <button
        focusOnError
        type="submit"
        id="approvalContinueButton"
        googleAnalyticsClick="Acceptance-Continue"
        class="ecl-button-primary"
      >
        {{ content.buttons.continue | uppercase }}
      </button>
    </div>
  </form>
  <div *ngIf="emailVerified" class="continue-button-container">
    <button
      (click)="skipEmailVerification()"
      id="skipConfirmationContinueButton"
      class="ecl-button-primary"
      googleAnalyticsClick="SkipConfirmationContinueButton"
    >
      {{ content.buttons.skipEmailVerification | uppercase }}
    </button>
  </div>
</ng-container>
<app-debug-hud>
  <button (click)="populateConfirmationCode()">
    Populate Activation Code
  </button>
  <button (click)="skipEmailVerification()">
    Skip Email Verification
  </button>
</app-debug-hud>
